import {Component, OnInit, ViewChild} from '@angular/core';
import { environment } from '@env/environment';
import {UiKitModalComponent} from '@app/modules/shared/components/ui-pack/ui-kit-modal/ui-kit-modal.component';

@Component({
  selector: 'app-main-modal',
  templateUrl: './main-modal.component.html',
  styleUrls: ['./main-modal.component.scss']
})
export class MainModalComponent implements OnInit {
  @ViewChild('modalTest', { static: true}) modalTest: UiKitModalComponent;
  constructor() { }

  ngOnInit() {
    if (environment.globalMessageModalActive) {
      setTimeout(() => {
        this.modalTest.show();
      }, 2000);
    }
  }

  onAccept() {
    this.modalTest.hide();
  }
}
