import { Component, OnInit, Input } from '@angular/core';
import { Period } from '@app/classes/inscription/period';
import { Inscription } from '@app/classes/inscription/inscription';
import { AcademyService } from '@app/services/academy/academy.service';
import { Session } from '@app/classes/user/session';
import { AppSettings } from '@app/helpers/app.settings';
import { LoadingMainService } from '@app/services/ui/loading-main.service';

declare var UIkit: any;

@Component({
  selector: 'app-inscription-history',
  providers: [LoadingMainService, AcademyService],
  templateUrl: './inscription-history.component.html',
})
export class InscriptionHistoryComponent implements OnInit {
  constructor(
    private _academyService: AcademyService,
    private _mainLoading: LoadingMainService
  ) {}

  @Input() period: Period;

  systemSession: Session;

  userInscriptionList: Array<Inscription> = [];
  selectedInscription: Inscription = new Inscription();
  loadingInscriptionHistory = true;

  ngOnInit() {
    this.systemSession = Session.getInstance();
    this._getUserInscriptionHistory();
  }

  showDetails(inscription) {
    this.selectedInscription = inscription;
    const modal = UIkit.modal('#inscription-detail-modal');
    modal.show();
  }

  _getUserInscriptionHistory() {
    this.userInscriptionList = [];
    this.loadingInscriptionHistory = true;
    this._academyService
      .getInscriptionMember({ n_member: this.systemSession.getUser().number })
      .subscribe(
        (inscriptions: any) => {
          for (let i = 0; i < inscriptions.length; i++) {
            if (inscriptions[i].forms && inscriptions[i].forms.length > 0) {
              this.userInscriptionList.push(new Inscription(inscriptions[i]));
            }
          }
          this.loadingInscriptionHistory = false;
        },
        error => {
          console.error(
            'No se pudieron obtener los registros de las inscripciones.'
          );
          console.error(error);
          this.loadingInscriptionHistory = false;
        }
      );
  }

  public cancelInscription(idInscription) {
    UIkit.modal.confirm(
      '<b>¿ Esta seguro que desea eliminar la inscripción ?</b><br><span class="uk-text-uppercase  red"> Todos los elementos que conforman esta inscripción serán eliminados</span><span> por lo tanto los cupos serán liberados.</span>',
      () => {
        this._mainLoading.show();
        this._academyService.deleteInscription(idInscription).subscribe(
          (response: any) => {
            this._mainLoading.hide();
            UIkit.notify(
              'La Inscripción fué eliminada con éxito.',
              AppSettings.APP_NOTIFY_SUCCESS
            );
            this._getUserInscriptionHistory();
          },
          error => {
            this._mainLoading.hide();
            UIkit.notify(
              'No se pudo eliminar la inscripción.',
              AppSettings.APP_NOTIFY_SUCCESS
            );
            console.error(
              'No se pudieron obtener los registros de las inscripciones.'
            );
            console.error(error);
          }
        );
      },
      { center: true }
    );
  }

  public cancelInscriptionRecord(idInscriptionRecord) {
    UIkit.modal.confirm(
      '<b>¿ Esta seguro que desea eliminar el elemento de la inscripción ?</b><br><span class="uk-text-uppercase red"> Los cupos serán liberados.</span>',
      () => {
        this._mainLoading.show();
        this._academyService
          .deleteInscriptioRecord(idInscriptionRecord)
          .subscribe(
            (response: any) => {
              this._mainLoading.hide();
              UIkit.notify(
                'La Inscripción fué eliminada con éxito.',
                AppSettings.APP_NOTIFY_SUCCESS
              );
              UIkit.modal('#inscription-detail-modal').hide();
              this._getUserInscriptionHistory();
            },
            error => {
              this._mainLoading.hide();
              UIkit.notify(
                'No se pudo eliminar la inscripción.',
                AppSettings.APP_NOTIFY_DANGER
              );
              console.error(
                'No se pudieron obtener los registros de las inscripciones.'
              );
              console.error(error);
            }
          );
      },
      { center: true }
    );
  }
}
