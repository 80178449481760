import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../Service.js';
import { Router } from '@angular/router';
import { AppSettings } from '@app/helpers/app.settings';
import { AppHelper } from '@app/helpers/app.helper';

@Injectable()
export class ModuleService extends Service {
  constructor(private _router: Router, private _http: HttpClient) {
    super();
  }

  getModulesState(filter) {
    return this._http
      .get(
        this.API_ENDPOINT +
          'system/module?' +
          AppHelper.serializeObj(filter),
        this.APP_REQUEST_OPTIONS
      )
      .toPromise();
  }

  getModuleStatus(module = '') {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'status/' + module
    );
  }
}
