import { Component, OnInit } from '@angular/core';
import {MemberService} from '@app/services/member/member.service';
const emptyImage = require('../../assets/warnning.svg');

@Component({
  selector: 'app-unverified-account-module-restriction',
  templateUrl: './unverified-account-module-restriction.component.html',
  styleUrls: ['./unverified-account-module-restriction.component.scss']
})
export class UnverifiedAccountModuleRestrictionComponent implements OnInit {
  emptyImage = emptyImage;
  validating = true;
  accountConfirmed;
  constructor(
    private _userInformationService: MemberService
  ) { }

  ngOnInit() {
    this.getUserInformation();
  }

  getUserInformation() {
    this._userInformationService.getUserInfo()
      .subscribe(
        ({ email_confirmed: emailConfirmed }: any) => {
          this.accountConfirmed = emailConfirmed;
          this.validating = false;
        }, error => {
          console.error(error);
          this.validating = false;
        }
      );
  }

}
