import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ro-pagination',
  templateUrl: './ro-pagination.component.html',
  styleUrls: ['./ro-pagination.component.scss']
})
export class RoPaginationComponent implements OnInit {
  @Input() id: string;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

}
