import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Session} from '@app/classes/user/session';
import {catchError} from 'rxjs/operators';
import {AuthService} from '@app/services/auth/auth.service';
import { equals } from 'ramda';

const UNAUTHORIZED = 401;

const setRequestBearer = request => {
  const headers: HttpHeaders = new HttpHeaders({
    Authorization: Session.getInstance().getSessionAuthorization() || '',
    'ROCOTO-API-KEY': '1U3hPKS0vd03TDnHBmF92f0a5uZw7aqs'
  });
  return request.clone({
    headers
  });
};

const isLogin = request => request.url.includes('users/sign_in');
const isAuthenticated = request => !isLogin(request);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const customRequest = isAuthenticated(request) ? setRequestBearer(request) : request;
    return next.handle(customRequest)
      .pipe(
        catchError(
          error => {
            if (equals(error.status, UNAUTHORIZED) && isAuthenticated(request)) {
              this._authService.closeSession();
              return throwError(error);
            }
            return throwError(error);
          }
        )
      );
  }

}
