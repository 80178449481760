import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-search-widget',
  templateUrl: './profile-search-widget.component.html',
})
export class ProfileSearchWidgetComponent implements OnInit {
  constructor() {}

  notifications: any = [];

  ngOnInit() {}

  lookforUpdate() {}
}
