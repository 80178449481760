import { Component, OnInit, Input } from '@angular/core';

import { Notifier } from '@app/classes/notifier/notifier';

@Component({
  selector: 'app-notification-widget',
  templateUrl: './notification-widget.component.html',
})
export class NotificationWidgetComponent implements OnInit {
  constructor() {}

  @Input() notifier: Notifier;
  animateBadgeFlag = false;

  notifications: any = [];

  ngOnInit() {}

  lookforUpdate() {}
}
