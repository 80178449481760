import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  template: `
    <div
      class="ro-badge"
      [ngClass]="{ 'ro-badge-background': background }"
      [ngStyle]="{ backgroundColor: background }"
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  constructor() {}

  @Input('badgeBackground') background = '';

  ngOnInit() {}
}
