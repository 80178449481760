import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@app/helpers/app.settings';
import { Service } from '../Service.js';

@Injectable()
export class CommentService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  saveComment(data) {
    return this._http
      .post(
        this.API_ENDPOINT + 'post/comment',
        data,
        this.APP_REQUEST_OPTIONS
      )
      .toPromise()
      .catch((error) => {
        console.log(error);
        console.log(error._body);
      });
  }
}
