import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
})
export class RatingComponent implements OnInit {
  @Input() max: number;
  @Input() full = false;
  @Input() size = 'small';
  @Input() selectable = false;
  @Input() onSelectedStar: Function = undefined;

  selectStar = -1;
  starList: any = [];

  constructor() {}

  ngOnInit() {
    for (let i = 0; i < this.max; i++) {
      this.starList.push({ full: this.full });
    }
  }

  selectRatingItem(idx) {
    this.selectStar = idx;
    if (this.onSelectedStar !== undefined) {
      this.onSelectedStar(idx + 1);
    }
  }
}
