import {Component, Input, OnInit} from '@angular/core';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';

@Component({
  selector: 'app-recurring-payment-info',
  templateUrl: './recurring-payment-info.component.html',
  styleUrls: ['./recurring-payment-info.component.scss']
})
export class RecurringPaymentInfoComponent implements OnInit {

  @Input() userRecurringPayments: UserRecurringPayment;

  constructor() { }

  ngOnInit() {
  }

}
