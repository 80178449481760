import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss']
})
export class LeftNavigationComponent implements OnInit {
  @Input() navigationItems = [];
  constructor() { }

  ngOnInit() {
  }

}
