import { User } from './user';
import { AppSettings } from '../../helpers/app.settings';
import { Payment } from '@app/classes/payment/payment';
import { Notifier } from '@app/classes/notifier/notifier';

export class Session {
    private static instance: Session;

    private payment: Payment = new Payment();
    private notifier: Notifier = new Notifier();

    private sessionUser: User;
    private authorization: string;

    private constructor() {}

    static getInstance() {
        if (!Session.instance) {
            Session.instance = new Session();
        }
        return Session.instance;
    }

    // getting
    public getUser() {
        return this.sessionUser;
    }
    public getPayment() {
        return this.payment;
    }
    public getNotifier() {
        return this.notifier;
    }
    public getAuthorization() {
        return this.authorization;
    }

    // creating session
    public createSession(sessionStored, local?: boolean) {
        if (!local) {
            this.sessionUser = new User(
                sessionStored.user.username,
                sessionStored.user.email,
                sessionStored.user.member_number,
                sessionStored.user.profile_img,
                sessionStored.user.background_img,
                sessionStored.user.name,
                sessionStored.user.last_name,
                sessionStored.user.id_hash,
                sessionStored.user.key,
                sessionStored.user.updated_at,
                sessionStored.user.created_at
            );
        }
        if (local) {
            this.sessionUser = new User(
                sessionStored.user.username,
                sessionStored.user.email,
                sessionStored.user.number,
                sessionStored.user.profileImg,
                sessionStored.user.backgroundImg,
                sessionStored.user.name,
                sessionStored.user.lastName,
                sessionStored.user.id,
                sessionStored.user.key,
                sessionStored.user.updatedAt,
                sessionStored.user.createdAt
            );
        }

        if (sessionStored.authorization !== undefined) {
            this.authorization = sessionStored.authorization;
        }

        if (sessionStored.paymentItemList !== undefined) {
            this.payment.updateItemsList(sessionStored.paymentItemList);
            this.payment.onItemChangeSave = this.onItemsUpdate;
        }

        sessionStorage.setItem(
            AppSettings.APP_SESSION_NAME,
            JSON.stringify(this._prepareSessionToSave())
        );

        return this.sessionUser;
    }

    public existSession() {
        if (sessionStorage.getItem(AppSettings.APP_SESSION_NAME) === null) {
            return false;
        } else {
            const sessionStored = JSON.parse(
                sessionStorage.getItem(AppSettings.APP_SESSION_NAME)
            );
            this.createSession(sessionStored, true);
            return this.sessionUser;
        }
    }

    public isLogged() {
        if (sessionStorage.getItem(AppSettings.APP_SESSION_NAME) === null) {
            return false;
        } else {
            const sessionStored = JSON.parse(
                sessionStorage.getItem(AppSettings.APP_SESSION_NAME)
            );
            this.createSession(sessionStored, true);
            return true;
        }
    }

    public clearSession() {
        sessionStorage.removeItem(AppSettings.APP_SESSION_NAME);
        this._clearSession();
    }

    private _clearSession() {
        // clening object
        this.authorization = '';
        this.sessionUser = new User();
        this.payment = new Payment();
        this.notifier = new Notifier();
    }

    public getSessionAuthorization() {
        return this.authorization;
    }

    public saveSession() {
        sessionStorage.setItem(
            AppSettings.APP_SESSION_NAME,
            JSON.stringify(this._prepareSessionToSave())
        );
    }

    private _prepareSessionToSave() {
        return {
            user: this.sessionUser,
            paymentItemList: this.payment.itemList,
            authorization: this.authorization,
        };
    }

    // callback
    public onItemsUpdate = () => {
        sessionStorage.setItem(
            AppSettings.APP_SESSION_NAME,
            JSON.stringify(this._prepareSessionToSave())
        );
    }
}
