import {Component, Input, OnInit} from '@angular/core';
import { Period } from '@app/classes/inscription/period';
import { AcademyService } from '@app/services/academy/academy.service';
import { MemberService } from '@app/services/member/member.service';
import { Member } from '@app/classes/user/member';
import { Module } from '@app/classes/module/module';
import * as moment from 'moment';
import { find, propEq } from 'ramda';
import { environment } from '@env/environment';

const MONTH_LIMIT_RESTRICTION = environment.academies.monthLimitRestriction;

@Component({
  selector: 'app-inscription',
  providers: [AcademyService, MemberService, Module],
  templateUrl: './inscription.component.html',
})
export class InscriptionComponent implements OnInit {
  constructor(
    private _academyService: AcademyService,
    private _memberService: MemberService,
    private _moduleClass: Module,
  ) {}

  @Input() currentPeriod: Period;
  @Input() currentTime = '';
  tab = 1;
  titularMember: Member;

  verificationErrors: any = {};
  verificationRequiredPeriod = '';
  verificationRequiredPeriodMsg = '';
  verificationRequiredState = '';
  verificationRequiredStateMsg = '';

  private _members; // private property _item

  get members(): any {
    return this._members;
  }

  @Input()
  set members(val: any) {
    this._members = val;
    if (this._members.length > 0) {
      this._processMembers(this._members);
    }
  }

  ngOnInit() {
  }

  _processMembers(members) {
    const titularMember = find(propEq('Familiar', 'TI'), members);
    this.titularMember =  new Member(titularMember);
    this._makeVerificationMember();
  }
  _makeVerificationMember() {
    if (this.titularMember.state !== 'AC') {
      this.verificationErrors.state = true;
      this.verificationRequiredState = this.titularMember.state;
      this.verificationRequiredStateMsg =
        '<br> - Debe ser socio Activo pero usted es ';
    }

    if (this.titularMember.type !== 'VI') {
      if (
        this._verifyPaymentPeriod(
          this.currentTime,
          this.titularMember.paymentPeriod,
          MONTH_LIMIT_RESTRICTION
        ) === false
      ) {
        this.verificationErrors.period = true;
        this.verificationRequiredPeriodMsg =
          '<br> - Debe estar al día en sus cuotas sociales hasta <span class="uk-text-uppercase">' +
          this.verificationRequiredPeriod +
          '</span>';
      }
    }
  }

  _verifyPaymentPeriod(current, paymentPeriod, months) {
    const currentDate = new Date(current);
    const fromDate = new Date(current);

    fromDate.setMonth(currentDate.getMonth() - months);
    fromDate.setDate(fromDate.getDate() + 1 - currentDate.getDate());
    fromDate.setHours(0, 0, 0);

    const verifyDateMoment = moment(paymentPeriod, 'YYYY-MM-DD');
    const fromDateMoment = moment(fromDate.toLocaleDateString(), 'DD-MM-YYYY');

    this.verificationRequiredPeriod = fromDateMoment.locale('es').format('MMMM-YYYY');
    return (
      verifyDateMoment.isAfter(fromDateMoment) ||
      verifyDateMoment.isSame(fromDateMoment)
    );
  }
}
