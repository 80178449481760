export class Group {
  public id: number = 0;
  public price: number = 0.0;
  public reState: string = '';
  public reSex: string = '';
  public reAge: any = [];
  public sAge: string = '';
  public description: string = '';
  public eKey: string = '';
  public idAcademy: number = 0;
  public validEKey: boolean;

  constructor(group?) {
    if (group !== undefined) {
      this.id = group.id_info ? group.id_info : 0;
      this.price = group.price ? group.price : 0;
      this.description = group.description ? group.description : 0;
      this.idAcademy = group.id_academy ? group.id_academy : 0;
      this.reState = group.re_state ? group.re_state : '';
      this.reSex = group.re_sex ? group.re_sex : '';
      this.reAge = group.re_age ? JSON.parse(group.re_age).age : [];
      this.sAge = group.s_age ? group.s_age : '';
      this.eKey = group.encrypted_key ? group.encrypted_key : '';
    }
  }
}
