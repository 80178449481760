import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationRoutingModule } from './application-routing.module';
import { UnverifiedAccountComponent } from './components/unverified-account/unverified-account.component';
import {MainComponent} from '@app/modules/application/pages/main/main.component';
import {PaymentModule} from '@app/modules/payment/payment.module';
import { SearchPageComponent } from '@app/modules/application/components/search/search-page/search-page.component';
import { SearchMainWidgetComponent } from '@app/modules/application/components/search/search-main-widget/search-main-widget.component';
import { NotificationPageComponent } from '@app/modules/application/components/notification/notification-page/notification-page.component';
import { NotificationWidgetComponent } from '@app/modules/application/components/notification/notification-widget/notification-widget.component';
import { MainModalComponent } from '@app/modules/application/components/main-modal/main-modal.component';
import { FeedbackComponent } from '@app/modules/application/components/feedback/feedback.component';
import { AppNotAvailableComponent } from '@app/modules/application/components/app-not-available/app-not-available.component';
import {SocialModule} from '@app/modules/social/social.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '@app/modules/shared/shared.module';
import { ApplicationHeaderComponent } from './components/application-header/application-header.component';
import { ApplicationLateralSideComponent } from './components/application-lateral-side/application-lateral-side.component';
import { LateralAppInfoComponent } from './components/lateral-app-info/lateral-app-info.component';
import { HeaderDropdownComponent } from './components/application-header/header-dropdown/header-dropdown.component';
import { HeaderMenuComponent } from './components/application-header/header-menu/header-menu.component';
import { HeaderUserButtonComponent } from './components/application-header/header-user-button/header-user-button.component';
import { UnverifiedAccountModuleRestrictionComponent } from './components/unverified-account-module-restriction/unverified-account-module-restriction.component';
import {NgZorroAntdModule, NzIconModule} from 'ng-zorro-antd';

@NgModule({
  declarations: [
    UnverifiedAccountComponent,
    MainComponent,
    SearchPageComponent,
    SearchMainWidgetComponent,
    NotificationPageComponent,
    NotificationWidgetComponent,
    MainModalComponent,
    FeedbackComponent,
    AppNotAvailableComponent,
    ApplicationHeaderComponent,
    ApplicationLateralSideComponent,
    LateralAppInfoComponent,
    HeaderDropdownComponent,
    HeaderMenuComponent,
    HeaderUserButtonComponent,
    UnverifiedAccountModuleRestrictionComponent,
  ],
    imports: [
        CommonModule,
        ApplicationRoutingModule,
        PaymentModule,
        SocialModule,
        FormsModule,
        SharedModule,
        NzIconModule,
        NgZorroAntdModule
    ],
  exports: [
    ApplicationRoutingModule,
    UnverifiedAccountModuleRestrictionComponent
  ]
})
export class ApplicationModule { }
