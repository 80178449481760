import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { User } from '@app/classes/user/user';
import { Notifier } from '@app/classes/notifier/notifier';
import { Session } from '@app/classes/user/session';
import { MemberService } from '@app/services/member/member.service';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';

declare var jQuery: any;

@Component({
  selector: 'app-application-header',
  templateUrl: './application-header.component.html',
  styleUrls: ['./application-header.component.scss'],
})
export class ApplicationHeaderComponent implements OnInit {
  @Input() userSession: User;
  @Input() isResponsiveMenuShown: boolean;
  @Output() closeSession: EventEmitter<any> = new EventEmitter<any>();
  @Output() showResponsiveMenu: EventEmitter<any> = new EventEmitter<any>();
  notifier: Notifier;
  menuItems = [];
  accountConfirmed = true;
  loadingUpdates = false;
  constructor(
    private _userInformationService: MemberService,
    private swUpdateService: SwUpdate
  ) {}

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (!this.accountConfirmed) {
      this.getUserInformation();
    }
  }

  ngOnInit() {
    this.getUserInformation();
    this.notifier = Session.getInstance().getNotifier();
    this.menuItems = [
      {
        name: 'Mi perfil web',
        path: '/user/' + this.userSession.username,
      },
      {
        name: 'Configurar mi perfil web',
        path: '/profile/setting',
      },
      {
        name: 'Configuración de cuenta',
        path: '/account-settings',
      },
    ];
  }
  onNavClick() {
    jQuery('.club-header-dropdown-content.active').removeClass('active');
  }

  getUserInformation() {
    this._userInformationService.getUserInfo().subscribe(
      ({ email_confirmed: emailConfirmed }: any) => {
        this.accountConfirmed = emailConfirmed;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  searchUpdate() {
    this.loadingUpdates = true;
    navigator.serviceWorker.getRegistration().then(function (reg) {
      if (reg) {
        reg.unregister().then(() => {
          window.location.reload(true);
        });
      } else {
        window.location.reload(true);
      }
    });
  }
}
