import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br',
})
export class Nl2brPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var span = document.createElement('span');
    if (!value) return value;
    var lines = value.split('\n');

    for (var i = 0; i < lines.length; i++) {
      span.innerText = lines[i];
      span.textContent = lines[i]; //for Firefox
      lines[i] = span.innerHTML;
    }
    return lines.join('<br />');
  }
}
