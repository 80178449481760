import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { equals } from 'ramda';
import {PaymentConstants} from '@app/modules/payment/payment-constants';
import {Payment} from '@app/classes/payment/payment';
import {Session} from '@app/classes/user/session';
import { gt, length, isNil, pipe, __ } from 'ramda';

@Component({
  selector: 'app-payment-page',
  providers: [AuthService],
  templateUrl: './payment-page.component.html',
})
export class PaymentPageComponent implements OnInit {
  constructor(
  ) {}

  title = 'Pagos en línea';
  payment: Payment;
  systemSession: Session = Session.getInstance();
  transaction: string;

  ngOnInit() {
    this.payment = this.systemSession.getPayment();
  }
}
