import {Component, Input, OnInit} from '@angular/core';
import { prop, cond, T, always } from 'ramda';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';

const stylesMap = {
  PROCESSING: {
    class: 'uk-badge-warning',
    icon: 'uk-icon-clock-o'
  },
  AFFILIATED: {
    class: 'uk-badge-success',
    icon: 'uk-icon-check'
  },
  REJECTED: {
    class: 'uk-badge-success',
    icon: 'uk-icon-check'
  }
};

@Component({
  selector: 'app-recurring-payment-status-indicator',
  templateUrl: './recurring-payment-status-indicator.component.html',
  styleUrls: ['./recurring-payment-status-indicator.component.scss']
})
export class RecurringPaymentStatusIndicatorComponent implements OnInit {
  @Input() userRecurringPayment: UserRecurringPayment;

  constructor() { }
  ngOnInit() {
  }

  get styleIndicator() {
    return cond([
      [recurring => recurring.isProcessingRequest, always(prop('PROCESSING', stylesMap))],
      [recurring => recurring.isRejected, always(prop('REJECTED', stylesMap))],
      [T, always(prop('AFFILIATED', stylesMap))],
    ])(this.userRecurringPayment);
  }

}
