export class Academy {
  public id: number = 0;
  public idType: number = 0;
  public name: string = '';
  public note: string = '';
  public video: string = '';

  constructor(academy?) {
    if (academy !== undefined) {
      this.id = academy.id_academy ? academy.id_academy : 0;
      this.idType = academy.id_type ? academy.id_type : 0;
      this.name = academy.name ? academy.name : 0;
      this.note = academy.note ? academy.note : 0;
      this.video = academy.video ? academy.video : 0;
    }
  }
}
