import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {InscriptionPageComponent} from '@app/modules/academy/pages/inscription-page/inscription-page.component';
import {InscriptionHistoryPageComponent} from '@app/modules/academy/pages/inscription-history-page/inscription-history-page.component';
import {InscriptionInfoPageComponent} from '@app/modules/academy/pages/inscription-info-page/inscription-info-page.component';
import {InscriptionFormPageComponent} from '@app/modules/academy/pages/inscription-form-page/inscription-form-page.component';
import {ModuleAvailabilityService} from '@app/resolvers/module-availability.service';


const routes: Routes = [
  {
    path: '',
    component: InscriptionPageComponent,
    children: [
      {
        path: 'history',
        component: InscriptionHistoryPageComponent
      },
      {
        path: 'form',
        component: InscriptionFormPageComponent,
        resolve: {
          moduleInfo: ModuleAvailabilityService
        },
      },
      {
        path: 'info',
        component: InscriptionInfoPageComponent
      },
      { path: '**', redirectTo: '/inscription/history' },
    ]
  },
  { path: '**', redirectTo: '/inscription/history' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcademyRoutingModule { }
