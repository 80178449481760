import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }

  headerHeight() {
    return jQuery('#application-header').height();
  }

  rightSectionWidth() {
    return jQuery('#right-section').width();
  }

  get feedStickyContentStyles() {
    return {
      height: `calc(100vh - ${this.headerHeight()}px)`,
      width: `${this.rightSectionWidth()}px`
    };
  }
}
