import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import RecurringPaymentConstants from '@app/modules/recurring-payments/recurring-payment-constants';
import * as moment from 'moment';
import { capitalizeFirst } from 'ramda-extension';
import {Session} from '@app/classes/user/session';
import {RecurringPaymentsStoreService} from '@app/modules/recurring-payments/data/recurring-payments-store.service';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';
import {RecurringPaymentService} from '@app/modules/recurring-payments/services/recurring-payment.service';
import {RoNotificationService} from '@app/modules/shared/services/ro-notification.service';
import {Router} from '@angular/router';
import {LoadingMainService} from '@app/services/ui/loading-main.service';

@Component({
  selector: 'app-recurring-payment-disaffiliation-form',
  templateUrl: './recurring-payment-disaffiliation-form.component.html',
  styleUrls: ['./recurring-payment-disaffiliation-form.component.scss']
})
export class RecurringPaymentDisaffiliationFormComponent implements OnInit {

  currentDateFriendly: string;
  currentDate: string;
  currentUserRecurringPayment: UserRecurringPayment;
  acceptTerms: false;

  constructor(
    private _recurringPaymentStore: RecurringPaymentsStoreService,
    private _recurringPaymentService: RecurringPaymentService,
    private _roNotificationService: RoNotificationService,
    private _routerService: Router,
    private _loadingMainService: LoadingMainService,
  ) { }

  ngOnInit() {
    const { userAffiliation } = this._recurringPaymentStore.recurrentPaymentState;
    this.currentUserRecurringPayment = userAffiliation;

    this.currentDate = moment().format('YYYY-MM-DD hh:mm:ss');
    this.currentDateFriendly = capitalizeFirst(moment().locale('es').format('LLLL').toString());
  }

  validateForm() {
    this._loadingMainService.show();
    this._recurringPaymentService.deleteUserRecurringPaymentInfo()
      .subscribe(
        response => {
          this._loadingMainService.hide();
          this._roNotificationService.success('Solicitud enviada satisfactoriamente.');
          this._routerService.navigate(['/recurring-payments']);
        },
        error => {
          this._loadingMainService.hide();
          this._roNotificationService.danger('Hubo un error procesando el formulario.');
        }
      );
  }
}
