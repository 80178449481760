export class Period {
  public id: number = 0;
  public name: string = '';
  public shortName: string = '';
  public start: string = '';
  public end: string = '';
  public abbreviation: string = '';

  constructor(period?) {
    if (period !== undefined) {
      this.id = period.id_period ? period.id_period : 0;
      this.name = period.name ? period.name : '';
      this.shortName = period.short_name ? period.short_name : 0;
      this.start = period.start ? period.start : 0;
      this.end = period.end ? period.end : 0;
      this.abbreviation = period.abbreviation || '';
    }
  }
}
