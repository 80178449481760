import { tryCatch, always, pipe, prop, applySpec } from 'ramda';
import { currencies } from '@app/modules/shared/pipes/currency.pipe';
import { PEN, USD } from '@app/helpers/currency-functions';
import { formatFriendlyDateTime } from '@app/helpers/date-functions';
import stc from 'string-to-color';

export interface IDebtInfo {
    type?: string;
    resourceId?: string;
    chargeId?: string;
}

export class Debt {
    selected: boolean;
    mount: number;
    type: string;
    description: string;
    period: string;
    currency: string;
    state: string;
    transaction: number;
    service: number;
    info: null;
    debtInfo: IDebtInfo = {};
    siblingsIds: number[] = [];
    createdAt: string;

    public id: number;

    public constructor(debt?, fromBackend = false) {
        this.mount = debt.mount || debt.Amount || 0;
        this.description = debt.description || debt.Glosa || '';
        this.period = debt.period || debt.Periodo || '';
        this.currency = debt.currency || debt.Moneda || '';
        this.type = debt.type || debt.Tipo || '';
        this.state = debt.state || debt.EstadoDeuda || '';
        this.transaction = debt.transaction || debt.TransaccionID || '';
        this.service = debt.service || debt.ServicioID || '';
        this.id = debt.id || debt.ID || '';
        this.siblingsIds = debt.siblingsIds || [];
        this.createdAt = debt.createdAt || debt.CreatedDate;
        if (debt.Intersocio_info) {
            this.debtInfo = pipe(
                tryCatch((jsonValue) => JSON.parse(jsonValue), always({})),
                applySpec({
                    type: prop('type'),
                    resourceId: prop('resource_id'),
                    chargeId: prop('charge_id'),
                })
            )(debt.Intersocio_info);
            this.info = tryCatch((jsonValue) => JSON.parse(jsonValue), always(null))(debt.Intersocio_info);
        } else {
            this.info = debt.info || null;
        }
    }

    get groupColor() {
        return stc(this.debtInfo.resourceId + 20);
    }

    get friendlyCreatedAt() {
        return formatFriendlyDateTime(this.createdAt);
    }

    get friendlyAmount() {
        return this.currency === currencies.PEN
            ? PEN(this.mount).format()
            : USD(this.mount).format();
    }

    select(value: boolean) {
        this.selected = value;
    }
}
