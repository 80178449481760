import { Debt } from './debt';
import { find, isNil, pipe, not, forEach, reduce, reject, propEq } from 'ramda';

export class Payment {
    constructor() {}
    public onItemChange: Function;
    public onItemChangeSave: Function;

    public itemList: Array<Debt> = [];

    // statics
    static create(obj: any) {
        const tmpPayment: Payment = new Payment();
        tmpPayment.itemList = obj.itemList;
        return tmpPayment;
    }

    static isEqualDebt(debt1: Debt, debt2: Debt) {
        return (
            debt1.id === debt2.id &&
            debt1.mount === debt2.mount &&
            debt1.description === debt2.description &&
            debt1.type === debt2.type
        );
    }

    addItem(item: Debt) {
        if (this.existInList(item)) {
            return;
        }
        this.itemList.push(item);
        if (!isNil(this.onItemChange)) {
            this.onItemChange();
        } // on item change event
        if (!isNil(this.onItemChangeSave)) {
            this.onItemChangeSave();
        }
    }

    removeItem(item: Debt) {
        this.itemList = reject(propEq('id', item.id), this.itemList);
        if (!isNil(this.onItemChange)) {
            this.onItemChange();
        }
        if (!isNil(this.onItemChangeSave)) {
            this.onItemChangeSave();
        }
    }

    existInList(debt: Debt) {
        return pipe(
            find((listDebt) => Payment.isEqualDebt(listDebt, debt)),
            isNil,
            not
        )(this.itemList);
    }

    public getItemsCount() {
        return this.itemList.length;
    }

    getItems() {
        return this.itemList;
    }

    getItemsId() {
        const tmpArray = [];
        for (let i = 0; i < this.itemList.length; i++) {
            tmpArray.push(this.itemList[i].id);
        }
        return tmpArray;
    }

    removeAllItems() {
        this.itemList = [];
        if (!isNil(this.onItemChange)) {
            this.onItemChange();
        }
        if (!isNil(this.onItemChangeSave)) {
            this.onItemChangeSave();
        }
    }

    getTotal(format?) {
        const total = reduce((prev, debt) => debt.mount + prev, 0, this.itemList);
        return total.toFixed(2); // 2decimals
    }

    updateItemsList(list: any) {
        this.itemList = [];
        forEach((item) => this.addItem(new Debt(item)), list);
    }
}
