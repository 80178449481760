import { InscriptionRecord } from './inscription-record';
import { User } from '../user/user';
import { Period } from '@app/classes/inscription/period';

export class Inscription {
  public id: string;
  public records: Array<InscriptionRecord> = [];

  public userRegistration: User;

  public paid = false;

  public period: Period;
  public username = '';
  public createdAt = '';
  public number = '';

  private errors: any;

  constructor(inscription?) {
    if (inscription !== undefined) {
      this.id =
        inscription.id_inscription != undefined
          ? inscription.id_inscription
          : 0;
      this.username = inscription.user != undefined ? inscription.user : '';
      this.number = inscription.nMember != undefined ? inscription.nMember : '';
      this.createdAt =
        inscription.created_at != undefined ? inscription.created_at : '';

      if (inscription.forms !== undefined) {
        for (let i = 0; i < inscription.forms.length; i++) {
          this.records.push(new InscriptionRecord(inscription.forms[i]));
        }
      }
    }
  }

  paidState() {
    const paid = false;
    const countItems = this.records.length;
    let countPaid = 0;
    for (let i = 0; i < this.records.length; i++) {
      if (this.records[i].confirmed) { countPaid++; }
    }
    if (countItems == 0) { return 2; }
    if (countItems == countPaid) { return 1; }
    if (countPaid == 0) { return 2; }
    return 3;
  }

  addRecord(record: InscriptionRecord) {
    for (let i = 0; i < this.records.length; i++) {
      if (this._isEqualInscriptionRecord(this.records[i], record)) { return false;
    } }

    this.records.push(record);
    return true;
  }

  removeRecord(record: InscriptionRecord) {
    for (let i = 0; i < this.records.length; i++) {
      if (this._isEqualInscriptionRecord(this.records[i], record)) {
        this.records.splice(i, 1);
      }
    }
  }

  getRecords() {
    return this.records;
  }

  getCountRecords() {
    return this.records.length;
  }

  getTotal() {
    let total = 0.0;
    for (let i = 0; i < this.records.length; i++) {
      total += this.records[i].getPrice();
    }
    return total;
  }

  prepareForService() {
    return {
      inscription: {
        id_period: this.period.id,
        nMember: this.number,
        user: this.username,
        forms_attributes: this.prepareRecordsForService(),
      },
    };
  }

  prepareRecordsForService() {
    const recordsForService: any = [];
    for (let i = 0; i < this.records.length; i++) {
      recordsForService.push({
        id_period: this.records[i].period.id,
        user: this.username,
        DNI: this.records[i].member.document,
        name: this.records[i].member.fullName,
        nMember: this.records[i].member.number,
        description: this.records[i].description,
        s_description: this.records[i].sDescription,
        price: this.records[i].group.price,
        id_academy: this.records[i].academy.id,
        id_info: this.records[i].group.id,
        id_schedule: this.records[i].schedule.id,
        id_service: this.records[i].schedule.idService,
        group_key: this.records[i].groupKey,
      });
    }

    return recordsForService;
  }

  existAcademyInInscription(academy, group, schedule) {
    let count = 0;
    for (let i = 0; i < this.records.length; i++) {
      if (
        academy.id == this.records[i].academy.id &&
        group.id == this.records[i].group.id &&
        schedule.id == this.records[i].schedule.id
      ) {
        count++;
      }
    }

    return count;
  }

  //

  _isEqualInscriptionRecord(record1, record2) {
    // verify dni member
    if (
      record1.academy.id == record2.academy.id &&
      record1.group.id == record2.group.id &&
      record1.schedule.id == record2.schedule.id &&
      record1.member.document == record2.member.document
    ) {
      return true;
    }

    return false;
  }
}
