export class PaymentConstants {
  static get COOKIE_NAMES() {
    return {
      TRANSACTION_ID: 'transaction_id',
      TRANSACTION_TOKEN: 'transaction_token'
    };
  }

  static get PAGE_MODES() {
    return {
      MAIN_PAGE: {
        code: '2431d3bdeffe077d1e02174f3e13d7f7',
        description: 'main',
      },
      VISA_PAGE: {
        code: '63ce5df4a6b95d049b47c7d6b67e7c4b',
        description: 'visa',
      }
    };
  }

  static get STEPS() {
    return [
      {
        title: 'Verificación',
        subTitle: 'Verifique los datos y montos a pagar.',
        solved: false,
        icon: 'eye',
      },
      {
        title: 'Método de Pago',
        subTitle: 'Elija el método de pago.',
        solved: false,
        icon: 'list',
        disabled: true,
      },
      {
        title: 'Pago',
        subTitle: 'Ingrese los datos de pago.',
        solved: false,
        icon: 'money',
      },
    ];
  }
}
