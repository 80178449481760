import { Pipe, PipeTransform } from '@angular/core';

const data = [
  { code: 'A', description: 'EFECTIVO' },
  { code: 'B', description: 'VISA' },
  { code: 'C', description: 'VISANET' },
  { code: 'D', description: 'AMERICAN EXPRESS' },
  { code: 'E', description: 'MASTER' },
  { code: 'F', description: 'CARSA' },
  { code: 'G', description: 'CURACAO' },
  { code: 'H', description: 'DINNER' },
  { code: 'I', description: 'METRO' },
  { code: 'J', description: 'RIPLEY' },
  { code: 'K', description: 'TOPY TOP' },
  { code: 'L', description: 'CMR' },
  { code: 'M', description: 'MAESTRO' },
  { code: 'N', description: 'DEBITO AUTOMÁTICO' },
  { code: 'O', description: 'CHEQUE' },
  { code: 'P', description: 'PROCESOS' },
  { code: 'Q', description: 'ARCHIVO CONTINENTAL' },
  { code: 'R', description: 'ARCHIVO AMERICAN EXPRESS' },
  { code: 'S', description: 'ARCHIVO CMR' },
  { code: 'T', description: 'ARCHIVO TELEFONICA' },
  { code: 'U', description: 'DEPOSITOS BANCO' },
  { code: 'V', description: 'ARCHIVO BANCO CREDITO' },
  { code: 'W', description: 'Financiero' },
  { code: 'X', description: 'Interbank' },
  { code: 'Y', description: 'Caja Municipal' },
  { code: 'Z2', description: 'INTERSOCIO' },
];

@Pipe({
  name: 'wayPay',
})
export class WayPayPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    for (let i = 0; i < data.length; i++)
      if (value == data[i].code) return data[i].description;
    return null;
  }
}
