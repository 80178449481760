import {
  Component,
  Input,
  ElementRef,
  HostBinding,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  OnInit,
} from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'owl-carousel',
  template: `
    <ng-content></ng-content>
  `,
})
export class OwlCarouselComponent implements OnChanges, OnInit {
  @HostBinding('class') defaultClass = 'owl-carousel';
  @Input() options: object;
  @Input() themeClass = 'owl-theme';

  f = true;

  $owlElement: any;

  defaultOptions: any = {};

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.defaultClass = this.defaultClass + ' ' + this.themeClass;
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit() {
    // use default - empty
    for (let key in this.options) {
      this.defaultOptions[key] = this.options[key];
    }

    this.$owlElement = jQuery(this.el.nativeElement).owlCarousel(
      this.defaultOptions
    );
  }

  ngOnDestroy() {
    this.$owlElement.owlCarousel('destroy');
    this.$owlElement = null;
  }
}
