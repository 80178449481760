import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SystemService } from '@app/services/system/system.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

declare var jQuery: any;

@Component({
  selector: 'app-search-main-widget',
  providers: [SystemService],
  templateUrl: './search-main-widget.component.html',
})
export class SearchMainWidgetComponent implements OnInit {
  constructor(
    private _routerService: Router,
    private _element: ElementRef,
    private _route: ActivatedRoute,
    private _systemService: SystemService
  ) {}

  dataSearch: any = {};
  contentHTML: any = {};
  timeoutSearch: any = {};
  sessionUser: User;

  userResultList: any = [];
  loadingSearchHeader = false;

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
    this.contentHTML = jQuery(this._element.nativeElement).find(
      '.club-header-dropdown-content'
    );
  }

  typeAheadSearch(event) {
    this.dataSearch.keywords = event.replace(/\s+/g, ' '); // removing white spaces

    if (
      this.dataSearch.keywords == '' ||
      this.dataSearch.keywords == ' ' ||
      this.dataSearch.keywords.length < 3
    ) {
      this.contentHTML.removeClass('active');
      return;
    }

    const currentUrl = this._routerService.routerState.snapshot.url;
    if (!currentUrl.includes('/search')) {
      this.loadingSearchHeader = true;
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this._searchContent();
      }, 800);
      this.contentHTML.addClass('active');
    }
  }

  _searchContent() {
    this.loadingSearchHeader = true;

    this.dataSearch.key = this.sessionUser.key;
    this.dataSearch.id = this.sessionUser.id;

    this.dataSearch.limit = 5;
    this._systemService.searchContent(this.dataSearch).then(
      (response: any) => {
        this.userResultList = response.data.users;
        this.loadingSearchHeader = false;
      },
      error => {
        console.error('No se pudo buscar', error);
        this.loadingSearchHeader = false;
      }
    );
  }

  goToFullRresult() {
    this.contentHTML.removeClass('active');
    this._routerService.navigate(['/search'], {
      queryParams: { keywords: this.dataSearch.keywords },
    });
  }
}
