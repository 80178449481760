import {ILocalModule} from '@app/interfaces/ILocalModule';

const VirtualIdLocalModule: ILocalModule = {
  code: '4b1f2d80f937acf7f6dc2e17e99d618c',
  icon: 'idcard',
  localEnabled: true,
  name: 'Mi Carnet Virtual',
  url: 'virtual-id',
  publishEnabled: true,
  includeInNavigation: true,
};

export default VirtualIdLocalModule;
