import { Component, OnInit, Input } from '@angular/core';
import { Payment } from '@app/classes/payment/payment';
import { Session } from '@app/classes/user/session';
import {PEN} from '@app/helpers/currency-functions';

declare var jQuery: any;

@Component({
  selector: 'app-payment-cart',
  templateUrl: './payment-cart.component.html',
})
export class PaymentCartComponent implements OnInit {
  constructor() {
    this.systemSession = Session.getInstance();
  }

  systemSession: Session;
  payment: Payment = new Payment();

  ngOnInit() {
    this.payment = this.systemSession.getPayment();
  }
  get totalToPay() {
    return PEN(this.payment.getTotal()).format();
  }
}
