import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { ModuleService } from '@app/services/system/module.service';
import { Session } from '@app/classes/user/session';
import { LoadingMainService } from '@app/services/ui/loading-main.service';
import { Module } from '@app/classes/module/module';
import { User } from '@app/classes/user/user';
import { DeepLinkService } from '@app/modules/application/services/deep-link.service';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-main',
  providers: [
    AuthService,
    ModuleService,
    LoadingMainService,
    Module,
    DeepLinkService,
  ],
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _moduleService: ModuleService,
    private _routerService: Router,
    private _routeService: ActivatedRoute,
    private _loadingMainService: LoadingMainService,
    private _moduleClass: Module,
    private _deepLinkService: DeepLinkService
  ) {}

  session: Session;
  sessionUser: User;
  isResponsiveMenuShown = false;

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
    setTimeout(() => {
      this.addJQueryEvents();
    }, 500);
    this.headerHeight();
    window.addEventListener('resize', () => {
      this.headerHeight();
    });
    this._deepLinkService.deepLinkingGoto();
  }

  @HostListener('window:click', ['$event.target'])
  onWindowClick(target) {
    this.handleLeftNavigationOutsideClick(target);
  }

  addJQueryEvents() {
    jQuery('.club-header-dropdown .club-header-button').on(
      'click',
      function (event) {
        jQuery('.club-header-dropdown-content')
          .not(jQuery(this).siblings('.club-header-dropdown-content'))
          .removeClass('active');
        const content = jQuery(this).siblings('.club-header-dropdown-content');
        content.toggleClass('active');
        event.preventDefault();
      }
    );

    jQuery(document).on('click', (event) => {
      if (
        !jQuery(event.target).is('.club-header-dropdown') &&
        !(jQuery(event.target).parents('.club-header-dropdown').length > 0)
      ) {
        jQuery('.club-header-dropdown-content.active').removeClass('active');
      }
    });
  }

  toggleMenuVisibility() {
    this.isResponsiveMenuShown = !this.isResponsiveMenuShown;
    const bodyElement = jQuery('body');
    this.isResponsiveMenuShown
      ? bodyElement.addClass('active-nav')
      : bodyElement.removeClass('active-nav');
  }

  handleLeftNavigationOutsideClick(target: any) {
    if (this.isResponsiveMenuShown) {
      const navigation = document.getElementById('intersocio-left-navigation');
      const navigationButton = document.getElementById(
        'intersocio-toggle-navigation-button'
      );
      const isNavigationOutsideClick =
        !navigation.contains(target) && !navigationButton.contains(target);
      if (isNavigationOutsideClick) {
        this.isResponsiveMenuShown = false;
      }
    }
  }

  closeSession() {
    this._authService.closeSession();
  }

  headerHeight() {
    return jQuery('#application-header').height();
  }
  get contentWrapperStyles() {
    return {
      paddingTop: `${this.headerHeight()}px`,
    };
  }
  get contentViewStyles() {
    return {
      height: `calc(100vh - ${this.headerHeight()}px)`,
    };
  }
}
