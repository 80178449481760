import { Component, OnInit } from '@angular/core';
import { Notifier } from '@app/classes/notifier/notifier';
import { Notification } from '@app/classes/notifier/notification';
import { Session } from '@app/classes/user/session';
import { Module } from '@app/classes/module/module';

@Component({
  selector: 'app-notification-page',
  providers: [Module],
  templateUrl: './notification-page.component.html',
})
export class NotificationPageComponent implements OnInit {
  constructor(private _moduleClass: Module) {}

  title = 'Notificaciones';
  notifier: Notifier;
  readerActive = false;
  currentNotification: Notification;
  session: Session;
  currentView = '';

  ngOnInit() {
    this.session = Session.getInstance();
    this.notifier = this.session.getNotifier();

    this._moduleClass.getModuleStatus(success => {
      if (success) {
        this.currentView = '310ec4e6213b134f6c2813bed00192195a756ee0';
        this._getNotifications();
      } else { this.currentView = '3c9d114ad9cd162804eff4057208bbd841203a17'; }
    }, 'academies');
  }

  private _getNotifications() {}

  showNotification(notification: Notification) {
    this.currentNotification = notification;
    this.currentNotification.read();
    this.readerActive = true;
  }

  hideNotification() {
    this.readerActive = false;
  }
}
