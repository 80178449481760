import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-user-button',
  templateUrl: './header-user-button.component.html',
  styleUrls: ['./header-user-button.component.scss']
})
export class HeaderUserButtonComponent implements OnInit {
  @Input() userSession: any = {};
  constructor() { }

  ngOnInit() {
  }

}
