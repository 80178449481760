import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Service } from '../Service.js';
import { AppSettings } from '@app/helpers/app.settings';
import { Session } from '@app/classes/user/session';
import { User } from '@app/classes/user/user';

@Injectable()
export class AuthService extends Service {
  session: Session;

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _routerService: Router
  ) {
    super();
    this.session = Session.getInstance();
  }

  login(data) {
    return this._http
      .post(
        this.API_ENDPOINT + 'auth/login',
        data,
        this.APP_REQUEST_OPTIONS
      )
      .toPromise()
      .catch((error) => {
        console.log(error);
        console.log(error._body);
      });
  }

  login2(user) {
    return this._http.post(this.API_ENDPOINT_API_INTERSOCIO + 'users/sign_in', {
      user,
    }, {observe: 'response'});
  }

  registration(user) {
    console.log(user);
    return this._http.post(this.API_ENDPOINT_API_INTERSOCIO + 'users', { user });
  }

  checkCredentials() {
    const responseSession = this.session.existSession();
    if (responseSession !== false) {
      return responseSession;
    } else {
      this._router.navigate(['login']);
      return new User();
    }
  }

  closeSession() {
    this.session.clearSession();
    this._routerService.navigate(['login']);
  }
}
