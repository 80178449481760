import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from '@app/modules/dashboard/components/dashboard/dashboard.component';
import {SharedModule} from '@app/modules/shared/shared.module';
import {SocialModule} from '@app/modules/social/social.module';

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    SocialModule
  ],
  exports: [
    DashboardComponent
  ]
})
export class DashboardModule { }
