import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  constructor() {}

  @Input('modal-id') id: string;
  @Input('modal-size') size = '';

  ngOnInit() {}
}
