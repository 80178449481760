import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url2a',
})
export class Url2aPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    //replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6}.[a-zA-Z]{2,6}|\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;

    if (value.match(replacePattern1) != null)
      value.match(replacePattern1).forEach(function(url) {
        value = value.replace(
          replacePattern1,
          '<a href="$1" target="_blank">$1</a>'
        );
      });
    if (value.match(replacePattern2) != null)
      value.match(replacePattern2).forEach(function(url) {
        value = value.replace(
          replacePattern2,
          '$1<a href="http://$2" target="_blank">$2</a>'
        );
      });

    /*
            if( value.match(replacePattern3) != null )
                value.match(replacePattern3).forEach(function(url) {
                    value = value.replace(replacePattern3, "<a href=\"mailto:$1\">$1</a>");
                });
        */

    return value;
  }
}
