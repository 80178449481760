import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@app/services/auth/auth.service';

import { CommentService } from '@app/services/social/comment.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-comment-form',
  providers: [CommentService],
  templateUrl: './comment-form.component.html',
})
export class CommentFormComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _commentService: CommentService
  ) {}

  sessionUser: User;
  loadingCommentForm = false;

  @Input() postId: number;
  @Input() lastComment: Function;

  comment = '';

  ngOnInit() {
    // getting session info
    this.sessionUser = Session.getInstance().getUser();
  }

  makeComment = () => {
    this.sendComment();
  }

  sendComment() {
    const data: any = {};
    data.key = this.sessionUser.key;
    data.user = this.sessionUser.user;
    data.id = this.sessionUser.id;
    data.id_post = this.postId;
    data.comment = this.comment;

    this.loadingCommentForm = true;
    this._commentService.saveComment(data).then(
      (response: any) => {
        if (response.success) { this.lastComment(response.data); }
        else { response.error('No se pudo guardar el comentario.'); }

        this.loadingCommentForm = false;
        this.comment = '';
      },
      error => {
        console.error(error);
        this.loadingCommentForm = false;
      }
    );
  }
}
