import RecurringPaymentModuleInfo from './modules/recurring-payments/recurring-payments-local.module';
import MeetingModuleInfo from './modules/meeting/meeting-local.module';
import MemberStateModuleInfo from './modules/member-state/member-state-local.module';
import MemberUpdateInformationModuleInfo from './modules/member-information-update/member-information-update-local.module';
import {
  PollModuleInfo,
  SigningModuleInfo,
} from './modules/polling/polling-local.module';
import BookingModuleInfo from './modules/booking/booking-local.module';
import AcademyModuleInfo from './modules/academy/academy-local.module';
import VirtualIdLocalModule from './modules/virtual-id/virtual-id-local.module';

export class AppConstants {
  static get MODULES_IDS(): any {
    return {
      NOT_AVAILABLE: 'cf17a251ea63ad48cd20ce2ea212a84330487626',
      APP_VIEW: '9239ee2cda84eca4c3440e2a7b50148af67da3d4',
    };
  }
  static get INSTALLED_MODULES(): any {
    return {
      HOME: {
        code: '2bbb3b3fcc50ad33bfb39f2c068fbbae',
        localEnabled: true,
        includeInNavigation: true,
        name: 'Inicio',
        publishEnabled: true,
      },
      INSCRIPTION: {
        code: '68eaac650f98dc404f79ba7f85ab7754',
        localEnabled: true,
        includeInNavigation: true,
      },
      NOTIFICATION: {
        code: 'e7b16d94eaa246634f781777e36ecebe',
        localEnabled: true,
        includeInNavigation: false,
      },
      ACADEMY: AcademyModuleInfo,
      MEMBER_STATE: MemberStateModuleInfo,
      RECURRING_PAYMENTS: RecurringPaymentModuleInfo,
      MEETING: MeetingModuleInfo,
      UPDATE_INFORMATION: MemberUpdateInformationModuleInfo,
      POLLING: PollModuleInfo,
      SIGNING: SigningModuleInfo,
      BOOKING: BookingModuleInfo,
      VIRTUAL_ID: VirtualIdLocalModule,
    };
  }
}
