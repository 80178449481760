import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ModuleService} from '@app/services/system/module.service';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Module} from '@app/classes/module/module';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class ModuleAvailabilityService implements Resolve<> {
  constructor(
    private _moduleService: ModuleService,
    private _moduleClass: Module
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // your logic goes here
    return this._moduleService.getModuleStatus(route.data.moduleId)
      .pipe(
        map( (moduleStatus: any) => {
          return {
            available: environment.production ? this._moduleClass.moduleIsAvailable(moduleStatus) : true,
            currentTime: moduleStatus.time
          };
        })
      );
  }
}
