export class Person {
  public dni: string = '';
  public id: number = 0;
  public names: string = '';
  public surnames: string = '';
  public fullName: string = '';

  constructor(person?) {
    if (person) {
      this.id = person.id || 0;
      this.surnames = person.surnames || '';
      this.names = person.names || '';
      this.fullName = this.names + ', ' + this.surnames;
    }
  }

  parseToSave() {
    return {
      names: this.names,
      surnames: this.surnames,
      dni: this.dni || null,
    };
  }
}
