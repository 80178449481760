import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@app/modules/application/pages/main/main.component';
import { NotificationPageComponent } from '@app/modules/application/components/notification/notification-page/notification-page.component';
import { SearchPageComponent } from '@app/modules/application/components/search/search-page/search-page.component';
import { ProfilePageComponent } from '@app/modules/social/components/profile/profile-page/profile-page.component';
import { ModuleAvailabilityService } from '@app/resolvers/module-availability.service';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/modules/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
      },
      {
        path: 'notification',
        component: NotificationPageComponent,
      },
      {
        path: 'search',
        component: SearchPageComponent,
      },
      {
        path: 'user/:user',
        component: ProfilePageComponent,
      },
      {
        path: 'user',
        redirectTo: '',
      },
      {
        path: 'profile/setting',
        loadChildren: () =>
          import('@app/modules/web-profile/web-profile.module').then(
            m => m.WebProfileModule
          ),
      },
      {
        path: 'account-settings',
        loadChildren: () =>
          import('@app/modules/account-settings/account-settings.module').then(
            m => m.AccountSettingsModule
          ),
      },
      {
        path: 'state',
        loadChildren: () =>
          import('@app/modules/member-state/member-state.module').then(
            m => m.MemberStateModule
          ),
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('@app/modules/payment/payment.module').then(
            m => m.PaymentModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('@app/modules/meeting/meeting.module').then(
            m => m.MeetingModule
          ),
      },
      {
        path: 'polling',
        loadChildren: () =>
          import('@app/modules/polling/polling.module').then(
            m => m.PollingModule
          ),
      },
      {
        path: 'member-information',
        loadChildren: () =>
          import(
            '@app/modules/member-information-update/member-information-update.module'
          ).then(m => m.MemberInformationUpdateModule),
      },
      {
        path: 'recurring-payments',
        loadChildren: () =>
          import(
            '@app/modules/recurring-payments/recurring-payments.module'
          ).then(m => m.RecurringPaymentsModule),
        data: {
          moduleId: 'academies',
        },
        resolve: {
          moduleInfo: ModuleAvailabilityService,
        },
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('@app/modules/booking/booking.module').then(
            m => m.BookingModule
          ),
      },
      {
        path: 'inscription',
        loadChildren: () =>
          import('@app/modules/academy/academy.module').then(
            m => m.AcademyModule
          ),
        data: {
          moduleId: 'academies',
        },
        resolve: {
          moduleInfo: ModuleAvailabilityService,
        },
      },
      {
        path: 'virtual-id',
        loadChildren: () =>
          import('@app/modules/virtual-id/virtual-id.module').then(
            m => m.VirtualIdModule
          ),
      },
    ],
  },
];

export const ApplicationRoutes = routes;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApplicationRoutingModule {}
