import {AfterViewInit, Component, ElementRef, EventEmitter, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import QrReader from 'react-qr-reader';

@Component({
  selector: 'app-qr-code-reader',
  templateUrl: './qr-code-reader.component.html',
  styleUrls: ['./qr-code-reader.component.scss']
})
export class QrCodeReaderComponent implements OnChanges, OnDestroy, AfterViewInit {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onScan: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('containerElementName', { static: true }) containerRef!: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    const props = {
      delay: 300,
      onScan: (data) => {
        this.onScan.emit(data);
      },
      onError: (error) => {
        this.onError.emit(error);
      },
    };
    ReactDOM.render(
      React.createElement(QrReader, props),
      this.containerRef.nativeElement
    );
  }

}
