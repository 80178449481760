import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  SwUpdate,
  UpdateActivatedEvent,
  UpdateAvailableEvent,
} from '@angular/service-worker';
import { roLogger } from '@app/helpers/roLogger';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-update-app-version',
  templateUrl: './update-app-version.component.html',
  styleUrls: ['./update-app-version.component.scss'],
})
export class UpdateAppVersionComponent implements OnInit {
  showSnackBar = false;
  logger = roLogger({ context: 'SwApplication' });
  timerSubscription: Subscription;
  retries = 10;

  constructor(private cdr: ChangeDetectorRef, private update: SwUpdate) {
    this.updateClient();
  }

  ngOnInit() {
    this.searchForUpdate();
  }

  updateApp() {
    this.update.activateUpdate().then(() => {
      this.logger('reloading the app after update.');
      window.location.reload();
    });
  }

  updateClient() {
    if (!this.update.isEnabled) {
      this.logger('The SW update is not enabled.');
      return;
    }
    this.update.available.subscribe((event: UpdateAvailableEvent) => {
      this.logger('available: Exist a new version available!');
      this.logger('Current app hash', event.current);
      this.logger('Available app hash', event.available);

      this.showSnackBar = true;
      this.cdr.detectChanges();
    });

    this.update.activated.subscribe((event: UpdateActivatedEvent) => {
      this.logger('activated: The version is activated!');
      this.logger('Previous app hash', event.previous);
      this.logger('Current app hash', event.current);
    });
  }

  searchForUpdate() {
    const timeInterval = interval(30 * 1000);

    this.timerSubscription = timeInterval.subscribe(() => {
      if (this.retries < 2) {
        this.timerSubscription.unsubscribe();
      }

      this.retries = this.retries - 1;
      this.logger('Time to check for updates!');
      this.update
        .checkForUpdate()
        .then(() => {
          this.logger('Checked for new version.');
        })
        .catch(() => {});
    });
  }
}
