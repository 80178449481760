import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { LikeService } from '@app/services/social/like.service';

import { AuthService } from '@app/services/auth/auth.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-like-btn',
  providers: [LikeService],
  templateUrl: './like-btn.component.html',
})
export class LikeBtnComponent implements OnInit {
  constructor(
    private _likeService: LikeService,
    private _authService: AuthService
  ) {}

  sessionUser: User;

  @Input() ownLike: boolean;
  @Input() likesCount: number;
  @Input() postId: number;
  @Output() onLiked = new EventEmitter<any>();
  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
  }

  makeLike($event) {
    if (this.ownLike == false) {
      this.likesCount++;
      this.ownLike = true;
    } else {
      this.likesCount--;
      this.ownLike = false;
    }

    this._sendLike({
      id_post: this.postId,
      action: this.ownLike,
      user: this.sessionUser.user,
    });
    this.onLiked.emit({ ownLike: this.ownLike, likesCount: this.likesCount });
  }

  private _sendLike(data) {
    data.key = this.sessionUser.key;
    data.id = this.sessionUser.id;
    this._likeService.makeLike(data).then(
      (response: any) => {},
      error => {
        console.error(error);
      }
    );
  }
}
