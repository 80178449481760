import { Component, OnInit, Input } from '@angular/core';
import { Period } from '@app/classes/inscription/period';
import { Academy } from '@app/classes/inscription/academy';
import { Group } from '@app/classes/inscription/group';
import { Schedule } from '@app/classes/inscription/schedule';
import { Member } from '@app/classes/user/member';
import { Inscription } from '@app/classes/inscription/inscription';
import { InscriptionRecord } from '@app/classes/inscription/inscription-record';

import { AcademyService } from '@app/services/academy/academy.service';
import { GroupService } from '@app/services/academy/group.service';
import { ScheduleService } from '@app/services/academy/schedule.service';

import { MemberService } from '@app/services/member/member.service';

import { LoadingMainService } from '@app/services/ui/loading-main.service';

import { AppSettings } from '@app/helpers/app.settings';
import { Session } from '@app/classes/user/session';

import * as sha1 from 'sha1';
import * as _ from 'lodash';
import { AppHelper } from '@app/helpers/app.helper';
import { RoNotificationService } from '@app/modules/shared/services/ro-notification.service';
import { error } from 'util';

declare var UIkit: any;
declare var jQuery: any;

const termsAndConditionsMsg: any =
  "<b>¿ Esta seguro que desea desear guardar los registros ingresados ?</b><p>Recuerde que no hay existen cambios ni devoluciones, y al proceder con la inscripción significa que usted esta de acuerdo con nuestros <a href='http://www.clubinter.org.pe/tyc/content/pre-inscripcion-academias' target='_blank'>Términos Y Condiciones </a>.</p>";

@Component({
  selector: 'app-inscription-form',
  providers: [AcademyService, GroupService, ScheduleService, MemberService],
  templateUrl: './inscription-form.component.html',
})
export class InscriptionFormComponent implements OnInit {
  constructor(
    private _academyService: AcademyService,
    private _groupService: GroupService,
    private _scheduleService: ScheduleService,
    private _loadingMainService: LoadingMainService,
    private _memberService: MemberService,
    private _roNotificationService: RoNotificationService
  ) {}

  sessionSystem: Session;
  academiesTypes: any = [];
  academies: Array<Academy> = [];
  groups: Array<Group> = [];
  schedules: Array<Schedule> = [];

  members: Array<Member> = [];
  currentInscription: Inscription = new Inscription();
  tmpInscriptionRecord: InscriptionRecord = new InscriptionRecord();
  contactNumber;
  contactNumberValidationStatus;
  monthlyInsurance = false;

  selectedMember: Member = undefined;
  selectedAcademyType: any = undefined;
  selectedAcademy: Academy = undefined;
  selectedGroup: Group = undefined;
  selectedSchedule: Schedule = undefined;
  verificationGroupCodeLoading = false;
  verificationGroupCode = '';
  quotaSchedule = new Schedule();
  availableQuota: any = {};
  localPeriod: Period;

  @Input()
  set period(period: Period) {
    this.localPeriod = period;
    this.currentInscription.period = this.localPeriod;
  }
  get period() {
    return this.localPeriod;
  }

  ngOnInit() {
    this.sessionSystem = Session.getInstance();
    this.currentInscription.username = this.sessionSystem.getUser().username;
    this.currentInscription.number = this.sessionSystem.getUser().number;
    this._getMembershipInfo();
    this._getAvailableAcademyTypes();
  }

  get isDisabledAddAcademy() {
    return (
      !this.selectedAcademy ||
      !this.selectedGroup ||
      !this.selectedSchedule ||
      this.selectedSchedule.availableQuota < 1 ||
      (this.selectedGroup.eKey && !this.selectedGroup.validEKey)
    );
  }

  addInscriptionRecord() {
    const errors: any = {};
    errors.formLocal = [];

    // local verification
    if (
      this.selectedMember.age < this.selectedGroup.reAge[0] ||
      this.selectedMember.age > this.selectedGroup.reAge[1]
    ) {
      errors.formLocal.push('El socio no cumple con los requisitos de edad.');
    }

    if (!this._verifyLocalQuota()) {
      errors.formLocal.push(
        'Ya no hay habrían cupos para el socio seleccionado.'
      );
    }

    if (
      !this._verifyLocalSex(this.selectedMember.sex, this.selectedGroup.reSex)
    ) {
      errors.formLocal.push('El socio no cumple el requisito de género.');
    }

    if (errors.formLocal.length > 0) {
      this._roNotificationService.danger(AppHelper.parseErrorResponse(errors));
      return;
    }
    const newInscriptionRecord = new InscriptionRecord({
      academy: this.selectedAcademy,
      group: this.selectedGroup,
      schedule: this.selectedSchedule,
      member: this.selectedMember,
      period: this.localPeriod,
    });

    if (this.selectedGroup.eKey && this.selectedGroup.validEKey) {
      newInscriptionRecord.setGroupKey(this.selectedGroup.eKey);
    }

    const success = this.currentInscription.addRecord(newInscriptionRecord);

    if (success) {
      this._roNotificationService.success('Registro agregado a la tabla.');
    } else {
      this._roNotificationService.danger(
        'El registro ya se encuentra en la tabla'
      );
    }
  }

  _verifyLocalQuota() {
    const currentEqualRecords =
      this.currentInscription.existAcademyInInscription(
        this.selectedAcademy,
        this.selectedGroup,
        this.selectedSchedule
      );
    if (currentEqualRecords > 0) {
      return this.selectedSchedule.availableQuota >= currentEqualRecords + 1;
    }
    return true;
  }

  _verifyLocalSex(sex, required) {
    if (required === 'damas') {
      return sex === 'F';
    }
    if (required === 'varones') {
      return sex === 'M';
    }
    return true;
  }

  removeInscriptionRecord(record: InscriptionRecord) {
    UIkit.modal.confirm(
      '<b>¿ Esta seguro que desea eliminar el registro seleccionado ?</b>',
      () => {
        this.currentInscription.removeRecord(record);
      },
      { center: true }
    );
  }

  getTotalInscription() {
    return this.currentInscription.getTotal();
  }
  validateGroupCode() {
    if (
      sha1(jQuery.trim(this.verificationGroupCode)) !== this.selectedGroup.eKey
    ) {
      const msg = jQuery('#verificationGroupCodeMsg');
      msg.addClass('shake');
      setTimeout(() => {
        msg.removeClass('shake');
      }, 500);
      this.selectedGroup.validEKey = false;
    } else {
      this.selectedGroup.validEKey = true;
    }

    this.verificationGroupCode = '';
  }

  saveInscription() {
    if (!this.contactNumber) {
      this.contactNumberValidationStatus = 'error';
      return;
    }
    UIkit.modal.confirm(
      termsAndConditionsMsg,
      () => {
        this.saveInscriptionService();
      },
      { center: true }
    );
  }

  saveInscriptionService() {
    this._loadingMainService.show();
    const dataPayload: any = this.currentInscription.prepareForService();
    dataPayload.inscription.contact_number = this.contactNumber;
    dataPayload.inscription.monthly_insurance = this.monthlyInsurance;
    this._academyService.saveInscription(dataPayload).subscribe(
      (inscriptionResponse: any) => {
        this._roNotificationService.modalAlert(
          AppSettings.APP_ALERT_TEMPLATE_ACADEMY_SUCCESS
        );
        // cleaning fields
        this.currentInscription = new Inscription();
        this.currentInscription.period = this.localPeriod;
        this.currentInscription.username =
          this.sessionSystem.getUser().username;
        this.currentInscription.number = this.sessionSystem.getUser().number;

        this.selectedAcademy = undefined;
        this.groups = [];
        this.selectedGroup = undefined;
        this.schedules = [];
        this.selectedSchedule = undefined;

        this.contactNumber = '';
        this.monthlyInsurance = false;

        this._loadingMainService.hide();
      },
      (error) => {
        this._loadingMainService.hide();
        try {
          const errors = JSON.parse(error._body); // parsing errors
          this._roNotificationService.danger(
            '<b>Errores existentes para la Inscripción.</b><br><br>' +
              AppHelper.parseErrorResponse(errors)
          );
        } catch (e) {
          this._roNotificationService.danger('Hubo un error en el proceso.');
        }
      }
    );
  }

  // getting values
  private _getAvailableAcademyTypes() {
    this._loadingMainService.show();
    this._academyService.getAvailableType().subscribe(
      (response: any) => {
        this.academiesTypes = response;
        this._loadingMainService.hide();
      },
      (error) => {
        console.error('No podemos obtener los de tipos de academias');
        console.error(error);
        this._loadingMainService.hide();
      }
    );
  }

  getAvailableAcademies() {
    // cleaning all values
    this.academies = [];
    this.selectedAcademy = undefined;
    this.groups = []; // cleaning values
    this.selectedGroup = undefined;
    this.schedules = []; // cleaning values
    this.selectedSchedule = undefined;
    this._getAvailableAcademies();
  }

  private _getAvailableAcademies() {
    if (!this.selectedAcademyType) {
      return;
    }
    this._loadingMainService.show();
    this._academyService
      .getAvailables({ type: this.selectedAcademyType.id_type })
      .subscribe(
        (availableAcademies: any) => {
          for (let i = 0; i < availableAcademies.length; i++) {
            this.academies.push(new Academy(availableAcademies[i]));
          }

          this.academies = _.sortBy(this.academies, [
            (a) => {
              return a.name;
            },
          ]);
          this._loadingMainService.hide();
        },
        (error) => {
          console.error('No podemos obtener las academias');
          console.error(error);
          this._loadingMainService.hide();
        }
      );
  }

  getAvailableGroups() {
    this.groups = []; // cleaning values
    this.selectedGroup = undefined;
    this.schedules = []; // cleaning values
    this.selectedSchedule = undefined;
    this._getAvailableGroups();
  }

  private _getAvailableGroups() {
    if (!this.selectedAcademy) {
      return;
    }
    this._loadingMainService.show();
    this._groupService.getAvailables(this.selectedAcademy.id).subscribe(
      (response: any) => {
        const availableGroups = response.groups;
        for (let i = 0; i < availableGroups.length; i++) {
          this.groups.push(new Group(availableGroups[i]));
        }
        this._loadingMainService.hide();
      },
      (error) => {
        console.error('No podemos obtener los grupos');
        console.error(error);
        this._loadingMainService.hide();
      }
    );
  }

  getAvailableSchedules() {
    this.schedules = []; // cleaning values
    this.selectedSchedule = undefined;
    this._getAvailbaleSchedules();
  }

  private _getAvailbaleSchedules() {
    if (!this.selectedAcademy || !this.selectedGroup) {
      return;
    }
    this._loadingMainService.show();
    this._scheduleService
      .getAvailables(this.selectedAcademy.id, this.selectedGroup.id)
      .subscribe(
        (response: any) => {
          const availableSchedules = response.schedules;
          for (let i = 0; i < availableSchedules.length; i++) {
            this.schedules.push(new Schedule(availableSchedules[i]));
          }
          this._loadingMainService.hide();
        },
        (error) => {
          console.error('No podemos obtener los horarios');
          console.error(error);
          this._loadingMainService.hide();
        }
      );
  }
  getAvailableSchedulesQuota() {
    this._getSchedules();
  }

  private _getSchedules() {
    if (!this.selectedSchedule) {
      return;
    }
    this._loadingMainService.show();
    this._scheduleService.getSchedule(this.selectedSchedule.id).subscribe(
      (quotaSchedule: any) => {
        this.quotaSchedule = new Schedule(quotaSchedule);
        this._loadingMainService.hide();
      },
      (error) => {
        console.error('No se pudo obtener el horario.');
        console.error(error);
        UIkit.notify(
          'No se pudo obtener la información.',
          AppSettings.APP_NOTIFY_DANGER
        );
        this._loadingMainService.hide();
      }
    );
  }

  private _getMembershipInfo() {
    this._loadingMainService.show();
    this._memberService.getMembershipInfo().subscribe(
      (response: any) => {
        const members = response;
        for (let i = 0; i < members.length; i++) {
          this.members.push(new Member(members[i]));
        }
        this.selectedMember = this.members[0];
        this._loadingMainService.hide();
      },
      (error) => {
        console.error('No podemos obtener los horarios');
        console.error(error);
        this._loadingMainService.hide();
      }
    );
  }
}
