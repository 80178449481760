import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { AuthService } from '@app/services/auth/auth.service';
import { ProfileService } from '@app/services/social/profile.service';
import { AppSettings } from '@app/helpers/app.settings';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-profile-update-image',
  templateUrl: './profile-update-image.component.html',
})
export class ProfileUpdateImageComponent implements OnInit, AfterViewInit {

  constructor(
    element: ElementRef,
    private _profileService: ProfileService,
    private _authService: AuthService
  ) {
    this.element = element;
  }
  element: ElementRef;

  modalId: String = 'edit-media-modal-';
  inputId: String = 'input-';
  cropId: String = 'crop-image-';
  cropViewportSetting: any = {};
  loadingUpdateImage = false;

  modal: any = {};
  $uploadCrop: any = {};
  sessionUser: User;

  @Input() type = 1;
  @Input() ownProfile = false;

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();

    // setting for images
    this.cropViewportSetting = {
      profile: {
        viewport: { width: 250, height: 250, type: 'square' },
        result: { width: 500, height: 500 },
      },
      background: {
        viewport: { width: 500, height: 188 },
        result: { width: 800, height: 300 },
      },
    };
  }

  ngAfterViewInit() {
    const __this = this;
    this.modal = UIkit.modal('#' + this.modalId + String(this.type));
    this.modal.on({
      'show.uk.modal'() {},
      'hide.uk.modal'() {
        jQuery('#' + __this.inputId + String(__this.type)).val('');
        __this.$uploadCrop.croppie('destroy');
        __this._initializeCrop();
      },
    });
    this._initializeCrop();
  }

  private _initializeCrop() {
    const __this = this;
    this.$uploadCrop = jQuery('#' + this.cropId + String(this.type)).croppie({
      viewport:
        __this.type == 1
          ? __this.cropViewportSetting.profile.viewport
          : __this.cropViewportSetting.background.viewport,
      enableExif: true,
      zoom: 0,
    });
  }

  openFileSelection() {
    jQuery('#' + this.inputId + String(this.type)).click();
  }

  uploadImage = input => {
    const __this = this;
    this.modal.show();
    if (input.srcElement.files && input.srcElement.files[0]) {
      __this.modal.show();
      const reader = new FileReader();
      reader.onload = (e: any) => {
        __this.$uploadCrop
          .croppie('bind', {
            url: e.target.result,
            zoom: 0,
          })
          .then(function() {});
      };
      reader.readAsDataURL(input.srcElement.files[0]);
    } else {
      console.error('No soporta el readfile api');
    }
  }

  selectImage() {
    const __this = this;
    this.$uploadCrop
      .croppie('result', {
        type: 'base64',
        size:
          __this.type == 1
            ? __this.cropViewportSetting.profile.result
            : __this.cropViewportSetting.background.result,
      })
      .then(function(resp) {
        __this.updateImage(resp);
      });
  }

  updateImage(base64Image) {
    const dataImage: any = {};
    dataImage.type = this.type;
    dataImage.image = base64Image;
    dataImage.key = this.sessionUser.key;
    dataImage.id = this.sessionUser.id;
    dataImage.user = this.sessionUser.user;

    this.loadingUpdateImage = true;
    this._profileService.updateProfileImage(dataImage).then(
      (response: any) => {
        if (response.success) {
          this.modal.hide();

          if (this.type == 1) { this.sessionUser.profileImg = response.data; }

          if (this.type == 2) { this.sessionUser.backgroundImg = response.data; }

          window.location.reload();
        } else {
          UIkit.notify('No se pudo actualizar.', AppSettings.APP_NOTIFY_DANGER);
        }

        this.loadingUpdateImage = false;
      },
      error => {
        console.error(error);
        console.error('No pudimos actualizar las imágenes.');
        this.loadingUpdateImage = false;
      }
    );
  }

  _castToString(value) {
    return String(value);
  }
}
