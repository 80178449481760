import { Component, OnInit } from '@angular/core';
import { SystemService } from '@app/services/system/system.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';
import * as Odometer from 'odometer';

@Component({
  selector: 'app-user-system',
  providers: [SystemService],
  templateUrl: './user-system.component.html',
  styles: [
    `
      .highlight-number {
        font-size: 23px;
        margin: 0 3px;
        display: inline-block;
        vertical-align: middle;
        line-height: 20px;
      }
    `,
  ],
})
export class UserSystemComponent implements OnInit {
  constructor(private _systemService: SystemService) {}

  sessionUser: User;
  odomoter: any;

  totalRegistered = 0;
  recentRegistered: any = [];

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
    this.getSystemRegistrationData();

    // count effect
    this.odomoter = new Odometer({
      el: document.querySelector('#members-count'),
      value: 0,
    });
  }

  getSystemRegistrationData() {
    const data: any = {};
    data.id = this.sessionUser.id;
    data.key = this.sessionUser.key;
    data.user = this.sessionUser.user;
    data.limit = 5;

    this._systemService.getSystemRegistrationInfo(data).then(
      (response: any) => {
        this.totalRegistered = response.data.total_registered;
        this.odomoter.update(this.totalRegistered);
        this.recentRegistered = response.data.recent_registered;
      },
      error => {
        console.error('No se pudo obtener la información de registro.', error);
      }
    );
  }
}
