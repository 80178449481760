import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Period} from '@app/classes/inscription/period';
import {AcademyService} from '@app/services/academy/academy.service';
import {MemberService} from '@app/services/member/member.service';
import { path } from 'ramda';
import { environment } from '@env/environment';

@Component({
  selector: 'app-inscription-page',
  templateUrl: './inscription-page.component.html',
  styleUrls: ['./inscription-page.component.scss']
})
export class InscriptionPageComponent implements OnInit {

  currentTime = '';
  currentPeriod: Period = new Period();
  members: Array<any> = [];
  moduleIsActive: boolean;
  navigation = [
    { name: 'Historial', path: '/inscription/history' },
    { name: 'incripciones', path: '/inscription/form' },
    { name: 'Información', path: '/inscription/info' },
  ];

  title = 'Academias';

  constructor(
    private activatedRoute: ActivatedRoute,
    private _academyService: AcademyService,
    private _memberService: MemberService,
  ) { }

  ngOnInit() {
    const { moduleInfo } = this.activatedRoute.snapshot.data;
    const { currentTime } = moduleInfo;
    this.currentTime = currentTime;
    this._getAcademyModuleState();
  }

  private _getAcademyModuleState() {
    this._academyService.getState().subscribe(
      (infoModule: any) => {
        if (path(['module', 0, 'state'], infoModule)) {
          this.moduleIsActive = true;
        } else {
          this.moduleIsActive = !environment.production;
        }
        this._getMemberState();
        this.currentPeriod = new Period(infoModule.period);
      },
      error => {
        console.error('No podemos obtener los horarios');
        console.error(error);
        this.moduleIsActive = false;
      }
    );
  }

  _getMemberState() {
    this._memberService.getMembershipInfo().subscribe(
      (members: any) => {
        this.members = members;
      },
      error => {
        console.error('No podemos obtener la información del socio');
        console.error(error);
        this.moduleIsActive = false;
      }
    );
  }
}
