import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PaymentPageComponent} from '@app/modules/payment/layout/payment-page/payment-page.component';
import {SessionGuard} from '@app/guards/session.guard';
import {PaymentVisaTimeoutComponent} from '@app/modules/payment/layout/payment-visa-timeout/payment-visa-timeout.component';
import {PaymentVisaResponsePageComponent} from '@app/modules/payment/layout/payment-visa-response-page/payment-visa-response-page.component';
import {VisaResponseGuard} from '@app/modules/payment/guards/visa-response.guard';


const routes: Routes = [
  {
    path: '',
    component: PaymentPageComponent
  },
  {
    path: '3961223659c486190',
    component: PaymentVisaResponsePageComponent,
    canActivate: [VisaResponseGuard]
  },
  {
    path: 'timeout-error',
    component: PaymentVisaTimeoutComponent,
    canActivate: [SessionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
