import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/services/auth/auth.service';
import { FeedsService } from '@app/services/social/feeds.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

@Component({
  selector: 'app-recent-activity',
  providers: [FeedsService],
  templateUrl: './recent-activity.component.html',
})
export class RecentActivityComponent implements OnInit {
  constructor(
    private _feedsService: FeedsService,
    private _authService: AuthService
  ) {}

  sessionUser: User;

  recentActivity: any = [];

  ngOnInit() {
    // getting session info
    this.sessionUser = Session.getInstance().getUser();
    this.getLastFeeds();
  }

  getLastFeeds() {
    const data: any = {};
    data.id = this.sessionUser.id;
    data.key = this.sessionUser.key;
    data.user = this.sessionUser.user;
    data.limit = 5;

    this._feedsService.getfeeds(data).then(
      (response: any) => {
        if (response.success) { this.recentActivity = response.data; }
      },
      error => {
        console.error('No se puede obtener los feeds');
      }
    );
  }
}
