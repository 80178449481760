import { Injectable } from '@angular/core';
import { Service } from '../Service.js';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@app/helpers/app.settings';
import { AppHelper } from '@app/helpers/app.helper';

@Injectable()
export class PersonService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  searchPerson(filter) {
    return this._http
      .get(
        this.API_ENDPOINT_API_INTERSOCIO +
          'booking/person?' +
          AppHelper.serializeObj(filter),
        this.APP_REQUEST_OPTIONS
      )
      .toPromise()
      .catch((error) => {
        console.log(error);
        console.log(error._body);
      });
  }

  savePerson(data) {
    return this._http.post(
      this.API_ENDPOINT_API_INTERSOCIO + 'booking/person',
      { person: data },
      this.APP_REQUEST_OPTIONS
    );
  }
}
