import { Component, Input, OnInit } from '@angular/core';
import { VisaService } from '@app/services/payment/visa.service';
import { find, propEq, propOr, pipe } from 'ramda';
import {NiubizResponse} from '@app/modules/payment/classes/niubiz-response';
import {PaymentResponse} from '@app/modules/payment/classes/payment-response';

@Component({
  selector: 'app-payment-visa-response-error',
  providers: [VisaService],
  templateUrl: './payment-visa-response-error.component.html',
  styleUrls: ['./payment-visa-response-error.component.scss'],
})
export class PaymentVisaResponseErrorComponent implements OnInit {
  @Input() transactionId: string;
  @Input() dataResponse: PaymentResponse;
  visaResponseParse: any = [];
  constructor(private _visaService: VisaService) {}

  ngOnInit() {
    this._getVisaResponseParse();
  }

  private _getVisaResponseParse() {
    this._visaService.getVisaResponseValue().subscribe(
      response => {
        this.visaResponseParse = response;
      },
      error => {
        console.error(error);
      }
    );
  }

  public visaResponseParser(code) {
    return pipe(
      find(propEq('code', Number(code))),
      propOr('NO DISPONIBLE', 'user_msg')
    )(this.visaResponseParse);
  }
}
