import { Component, OnInit } from '@angular/core';
import {Period} from '@app/classes/inscription/period';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@env/environment';
import {AcademyService} from '@app/services/academy/academy.service';
import {MemberService} from '@app/services/member/member.service';

@Component({
  selector: 'app-inscription-form-page',
  templateUrl: './inscription-form-page.component.html',
  styleUrls: ['./inscription-form-page.component.scss']
})
export class InscriptionFormPageComponent implements OnInit {
  currentTime = '';
  currentPeriod: Period = new Period();
  members: Array<any> = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private _academyService: AcademyService,
    private _memberService: MemberService,
  ) { }

  ngOnInit() {
    const { moduleInfo } = this.activatedRoute.snapshot.data;
    const { currentTime } = moduleInfo;
    this.currentTime = currentTime;
    this._getAcademyModuleState();
  }

  private _getAcademyModuleState() {
    this._academyService.getState().subscribe(
      (infoModule: any) => {
        this._getMemberState();
        this.currentPeriod = new Period(infoModule.period);
      },
      error => {
        console.error('No podemos obtener los horarios');
        console.error(error);
      }
    );
  }

  _getMemberState() {
    this._memberService.getMembershipInfo().subscribe(
      (members: any) => {
        this.members = members;
      },
      error => {
        console.error('No podemos obtener la información del socio');
        console.error(error);
      }
    );
  }
}
