import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-visa-timeout',
  templateUrl: './payment-visa-timeout.component.html',
  styleUrls: ['./payment-visa-timeout.component.scss']
})
export class PaymentVisaTimeoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
