import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-recommendation-widget',
  templateUrl: './recommendation-widget.component.html',
})
export class RecommendationWidgetComponent implements OnInit {
  @Input() title = '';
  @Input() content = '';

  constructor() {}

  ngOnInit() {}
}
