import { Component, OnInit, Input } from '@angular/core';
import { LikeService } from '@app/services/social/like.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';
import { ProfileWidgetComponent } from '@app/modules/social/components/profile/profile-widget/profile-widget.component';

declare var UIkit: any;

@Component({
  selector: 'app-like-preview',
  providers: [LikeService],
  templateUrl: './like-preview.component.html',
  host: {
    '(click)': 'showDetail($event)',
  },
})
export class LikePreviewComponent implements OnInit {
  constructor(private _likeService: LikeService) {}

  sessionUser: User;
  likeList: any = [];
  @Input() likes: any = [];
  @Input() likesCount = 0;
  @Input() idPost = 0;

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
  }

  showDetail(event) {
    this.getLikes();
  }

  private getLikes() {
    const data: any = {};
    data.user = this.sessionUser.user;
    data.id = this.sessionUser.id;
    data.key = this.sessionUser.key;
    data.all = true;
    data.id_post = this.idPost;

    this._likeService.getLikes(data).then(
      (response: any) => {
        this.likeList = response.data;
        UIkit.modal.blockUI(this._generateHTML(), {
          keyboard: true,
          bgclose: true,
          center: true,
        });
      },
      error => {
        console.error('ocurrio un error recuepernado los likes', error);
      }
    );
  }

  private _generateHTML() {
    let html =
      '<a class="uk-modal-close uk-close" style="position: absolute;right: 0;top: 0;"></a><div><p>Todos: ' +
      this.likeList.length +
      ' socios</p><hr><div class="uk-grid uk-grid-width-1-2">';
    // ToDo Improve this
    this.likeList.forEach(element => {
      html +=
        '<div style="margin-bottom: 10px;">' +
        ProfileWidgetComponent.getCustomComponentRendered(element.user_info) +
        '</div>';
    });
    html += '</div></div>';
    return html;
  }
}
