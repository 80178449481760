import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-module-disabled',
  templateUrl: './message-module-disabled.component.html',
})
export class MessageModuleDisabledComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
