import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Person } from '@app/classes/user/person';
import { PersonService } from '@app/services/system/person.service';

@Component({
  selector: 'app-search-person',
  template: `
    <div class="uk-form club-search-person">
      <input
        type="text"
        class="club-min-width-input"
        [(ngModel)]="searchText"
        name="searchText"
        placeholder="{{ placeholder }}"
        uppercaseOnly
        (ngModelChange)="search($event)"
      />
      <div class="club-search-person-results">
        <div class="loading" *ngIf="loading">Buscando...</div>
        <ul class="club-list" *ngIf="result.length > 0">
          <li
            class="item uk-text-truncate fadeIn animated"
            *ngFor="let person of result"
            (click)="selectPerson(person)"
          >
            {{ person.fullName }}
          </li>
        </ul>
      </div>
    </div>
  `,
  providers: [PersonService],
  styleUrls: ['./search-person.component.scss'],
})
export class SearchPersonComponent implements OnInit {
  constructor(private _personService: PersonService) {}

  @Input()
  set person(person: Person) {
    this._person = person;
    if (!person.id) { this.searchText = ''; }
  }
  get person() {
    return this._person;
  }

  _person: Person;
  @Output() personChange: EventEmitter<Person> = new EventEmitter();

  @Input() placeholder = '';

  searchText = '';
  timeout: any = {};
  maxResultsShown = 10;
  loading = false;
  showResults = false;
  result: Array<Person> = [];

  // Todo make a global autocomplete
  ngOnInit() {}

  selectPerson(person) {
    this.result = [];
    this.person = person;
    this.personChange.emit(this.person);
    this.searchText = this.person.fullName;
  }

  search(event) {
    if (event === '') {
      this.personChange.emit(new Person());
    }

    if (this.timeout) { window.clearTimeout(this.timeout); }

    this.timeout = window.setTimeout(() => {
      this.searchPerson(event);
    }, 800);
  }

  searchPerson(names) {
    if (names == '') { return; }
    this.loading = true;
    this._personService.searchPerson({ q: names }).then(
      (response: any) => {
        this.result = [];
        response.forEach(element => {
          this.result.push(new Person(element));
        });
        this.showResults = this.result.length > 0;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );
  }
}
