import { Pipe, PipeTransform } from '@angular/core';

const data = [
  { code: 'CO', description: 'CONYUGE' },
  { code: 'HE', description: 'HERMANA' },
  { code: 'HI', description: 'HIJO (A)' },
  { code: 'MA', description: 'MADRE' },
  { code: 'TI', description: 'TITULAR' },
  { code: 'XC', description: 'EX-CONYUGE' },
];

@Pipe({
  name: 'memberRelationship',
})
export class MemberRelationshipPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    for (let i = 0; i < data.length; i++)
      if (value == data[i].code) return data[i].description;
    return null;
  }
}
