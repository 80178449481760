export const environment = {
  production: true,
  version: '8.5.5',
  buildTimeStamp: 'Sat Feb 04 2023 13:08:10 GMT-0500 (Colombia Standard Time)',
  baseUrlRails: 'https://staging-api-intersocio.clubinter.org.pe/v1/',
  baseUrlCodeIgniter: 'https://dev-intersocio.clubinter.org.pe/api/v2/',
  baseUrlApiInter: 'https://staging-api-inter.clubinter.org.pe/v1/',
  baseUrlApp: 'https://intersocio.clubinter.org.pe/',
  baseUrlSlim: 'https://api-v2-intersocio.clubinter.org.pe/api/v1/',
  visa: {
    paymentButtonLib: 'https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true',
    merchantId: '522591303',
  },
  globalMessageModalActive: false,
  academies: {
    monthLimitRestriction: 5
  },
  polling: {
    signing: {
      url: 'https://atenea-31cd4.web.app/'
    },
    poll: {
      url: 'https://atenea-31cd4.web.app/'
    },
  },
};
