import { Notification } from './notification';

export class Notifier {
  private static instance: Notifier;
  public onItemChange: () => void = undefined;

  public constructor() {}

  notificationList: Array<Notification> = [];

  addNotification(notification: Notification) {
    this.notificationList.push(notification);

    if (this.onItemChange !== undefined) {
      this.onItemChange();
    } // on item change event
  }

  getNotificationCount() {
    return this.notificationList.length;
  }

  getNotificationUnrededCount() {
    let unreaded = 0;
    for (let i = 0; i < this.notificationList.length; i++) {
      if (!this.notificationList[i].readed) {
        unreaded++;
      }
    }

    return unreaded;
  }
}
