import { Injectable } from '@angular/core';
import {Service} from '@app/services/Service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountVerificationService extends Service {

  constructor(private _http: HttpClient) {
    super();
  }

  sendEmailVerification() {
    return this._http.get(`${this.API_ENDPOINT_API_INTERSOCIO}user_email`);
  }
}
