import {Component, Input, OnInit} from '@angular/core';
import {GroupService} from '@app/services/academy/group.service';
import {InscriptionRecord} from '@app/classes/inscription/inscription-record';

@Component({
  selector: 'app-inscription-note',
  templateUrl: './inscription-note.component.html',
  styleUrls: ['./inscription-note.component.scss'],
  providers: [ GroupService ]
})
export class InscriptionNoteComponent implements OnInit {
  @Input() inscription: InscriptionRecord;
  noteContent = '';
  constructor(
    private _academyService: GroupService,
  ) { }

  ngOnInit() {
    this.getAcademyInfo();
  }

  getAcademyInfo() {
    this._academyService.getAcademy(this.inscription.academyId)
      .subscribe(
        (response: any) => {
          this.noteContent = response.note;
        },
        error => {}
      );
  }
}
