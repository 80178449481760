export const PollModuleInfo =  {
  code: 'f965cf52cab93e83eb6c998148cb1899',
  icon: 'audit',
  localEnabled: true,
  name: 'Votaciones',
  url: 'polling/poll',
  includeInNavigation: true,
};

export const SigningModuleInfo =  {
  code: 'a492622e50a46e4f67048a3bc59d5640',
  icon: 'audit',
  localEnabled: true,
  name: 'Recolección de firmas',
  url: 'polling/signing',
  includeInNavigation: true,
};
