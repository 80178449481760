import { Component, Input, OnInit } from '@angular/core';
import {NiubizResponse} from '@app/modules/payment/classes/niubiz-response';
import {PaymentResponse} from '@app/modules/payment/classes/payment-response';

@Component({
  selector: 'app-payment-visa-response-success',
  templateUrl: './payment-visa-response-success.component.html',
  styleUrls: ['./payment-visa-response-success.component.scss'],
})
export class PaymentVisaResponseSuccessComponent implements OnInit {
  @Input() transactionId: string;
  @Input() dataResponse: PaymentResponse;
  constructor() {}

  ngOnInit() {}
}
