import { Pipe, PipeTransform } from '@angular/core';

const data = [
  { code: 'AC', description: 'ACTIVO' },
  { code: 'AU', description: 'AUSENTE' },
];

@Pipe({
  name: 'memberState',
})
export class MemberStatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    for (let i = 0; i < data.length; i++)
      if (value == data[i].code) return data[i].description;
    return null;
  }
}
