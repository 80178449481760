import { Injectable } from '@angular/core';
import { Service } from '../Service.js';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@app/helpers/app.settings';

@Injectable()
export class MemberService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getMembershipInfo() {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'membership/index',
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getUserInfo() {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'user',
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  updateUserInfo(data) {
    return this._http.put(
      this.API_ENDPOINT_API_INTERSOCIO + 'users',
      { user: data },
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  updateUserPassword(data) {
    return this._http.put(
      this.API_ENDPOINT_API_INTERSOCIO + 'user/password_verified',
      { user: data },
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }
}
