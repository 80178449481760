import { Directive, ElementRef, Input, NgZone } from '@angular/core';
import { NgModel } from '@angular/forms';

declare var jQuery: any;
declare var google: any;

@Directive({
  selector: '[ngModel][googlePlaces]',
  providers: [NgModel],
})
export class GooglePlacesDirective {
  autocomplete: any;

  @Input() getValueInput: Function;

  constructor(
    private ele: ElementRef,
    private model: NgModel,
    private _ngZone: NgZone
  ) {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.ele.nativeElement
    );
  }

  ngOnInit() {
    let __this = this;
    this._ngZone.runOutsideAngular(() => {
      this.autocomplete.addListener('place_changed', () => {
        this._ngZone.run(() => {
          let value = __this.ele.nativeElement.value;
          __this.model.control.setValue(value);
        });
      });
    });
  }
}
