import { Directive, ElementRef } from '@angular/core';

declare var jQuery: any;

@Directive({
  selector: '[passwordStrength]',
  host: {
    '(ngModelChange)': 'onInputChange($event)',
  },
})
export class PasswordStrengthDirective {
  styleClass: string = 'password-strength';
  messageElement: any;

  constructor(private ele: ElementRef) {
    jQuery(this.ele.nativeElement).after(
      jQuery('<span>', { class: this.styleClass })
    );
    this.messageElement = jQuery(this.ele.nativeElement).next(
      '.' + this.styleClass
    );
  }

  onInputChange(event) {
    this._checkStrength(event);
  }

  _checkStrength(password) {
    var strength = 0;

    if (password.length > 7) strength += 1;
    // If password contains both lower and uppercase characters, increase strength value.
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1;
    // If it has numbers and characters, increase strength value.
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/))
      strength += 1;
    // If it has one special character, increase strength value.
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1;
    // If it has two special characters, increase strength value.
    if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/))
      strength += 1;

    this.messageElement
      .removeClass('password-weak')
      .removeClass('password-good')
      .removeClass('password-strong');

    if (strength < 2) {
      this.messageElement.addClass('password-weak');
    } else if (strength == 2) {
      this.messageElement.addClass('password-good');
    } else {
      this.messageElement.addClass('password-strong');
    }
  }
}
