import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { equals } from 'ramda';

@Component({
  selector: 'app-profile-setting-page',
  templateUrl: './profile-setting-page.component.html',
})
export class ProfileSettingPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  title = 'Configuración de Perfil';
  tab = 1;

  ngOnInit() {
    this.readDeepLink();
  }
  readDeepLink() {
    const action = this.route.snapshot.queryParamMap.get('action');
    if (equals(action, 'edit-email')) {
      this.tab = 2;
      this.cleaningParams();
    }
  }
  cleaningParams() {
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: { } }
    );
  }
}
