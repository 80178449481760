import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProfileSettingPageComponent} from '@app/modules/web-profile/components/profile-setting-page/profile-setting-page.component';


const routes: Routes = [
  {
    path: '',
    component: ProfileSettingPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebProfileRoutingModule { }
