import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';
import {RecurringPaymentsStoreService} from '@app/modules/recurring-payments/data/recurring-payments-store.service';
import {Injectable} from '@angular/core';
import {RecurringPaymentService} from '@app/modules/recurring-payments/services/recurring-payment.service';
import {map, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecurringPaymentResolverService implements Resolve<any> {
  constructor(
    private _recurringPaymentStore: RecurringPaymentsStoreService,
    private _userRecurringPaymentService: RecurringPaymentService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._userRecurringPaymentService.getUserRecurringPaymentInfo()
      .pipe(
        map( response => {
          this._recurringPaymentStore.setCurrentUserRecurringPayment(new UserRecurringPayment(response));
          return new UserRecurringPayment();
        }),
        catchError(error => {
          return of(new UserRecurringPayment());
        })
      );
  }
}
