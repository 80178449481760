import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../Service.js';
import { AppSettings } from '@app/helpers/app.settings';
import { AppHelper } from '@app/helpers/app.helper';

@Injectable()
export class AcademyService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getState() {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'status/academies?t=' + new Date().getTime(),
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getAvailables(filter) {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO +
        'academies/available?' +
        AppHelper.serializeObj(filter),
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getAvailableType() {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'academies/types',
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  saveInscription(data) {
    return this._http.post(
      this.API_ENDPOINT_API_INTERSOCIO + 'academies/inscription',
      data,
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getInscriptionMember(filter) {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO +
        'academies/inscriptions?' +
        AppHelper.serializeObj(filter),
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  deleteInscription(idInscription) {
    return this._http.delete(
      this.API_ENDPOINT_API_INTERSOCIO + 'academies/inscription/' + idInscription,
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  deleteInscriptioRecord(idInscriptionRecord) {
    return this._http.delete(
      this.API_ENDPOINT_API_INTERSOCIO + 'academies/form/' + idInscriptionRecord,
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }
}
