import { Component, OnInit, Input } from '@angular/core';

import { ProfileService } from '@app/services/social/profile.service';
import { AuthService } from '@app/services/auth/auth.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

@Component({
  selector: 'app-profile-popular',
  providers: [AuthService, ProfileService],
  templateUrl: './profile-popular.component.html',
})
export class ProfilePopularComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _profileService: ProfileService
  ) {}

  @Input() limit = 4;
  sessionUser: User;
  profilePopularList: any = [];

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
    this.getProfilesPopular();
  }

  getProfilesPopular() {
    const data: any = {};
    data.key = this.sessionUser.key;
    data.user = this.sessionUser.user;
    data.id = this.sessionUser.id;
    data.limit = this.limit;

    this._profileService.getProfilePopular(data).then(
      (response: any) => {
        this.profilePopularList = response.data;
      },
      error => {
        console.error('No se pudo obtener los perfiles publicos.');
        console.error(error);
      }
    );
  }
}
