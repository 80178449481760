import { Component, OnInit } from '@angular/core';
import { VisaService } from '@app/services/payment/visa.service';
import { Session } from '@app/classes/user/session';
import { Payment } from '@app/classes/payment/payment';
import { AuthService } from '@app/services/auth/auth.service';
import { AppSettings } from '@app/helpers/app.settings';
import { environment } from '@env/environment';
import { RoNotificationService } from '@app/modules/shared/services/ro-notification.service';
import { isNil } from 'ramda';

declare var window: any;

const VISA_MERCHANT_ID = environment.visa.merchantId;
const VISA_FORM_SRC = environment.visa.paymentButtonLib;
const INTERSOCIO_TIMEOUT_URL = AppSettings.API_ENDPOINT_VISA.timeoutUrl;
const VISA_FORM_ACTION = AppSettings.API_ENDPOINT_VISA.redirectionUrl;
const INTERSOCIO_CONFIRN_URL = AppSettings.API_ENDPOINT_VISA.confirmUrl;
const INTERSOCIO_LOGO_URL = `${environment.baseUrlApp}assets/img/public/logo_for_visa.png`;

@Component({
  selector: 'app-payment-visa',
  providers: [VisaService, AuthService],
  templateUrl: './payment-visa.component.html',
})
export class PaymentVISAComponent implements OnInit {
  constructor(
    private _visaService: VisaService,
    private _authService: AuthService,
    private _roNotificationService: RoNotificationService
  ) {}

  loadingVisa = false;
  payment: Payment;
  systemSession: Session;

  static _generateUrlActionVisa(sessionToken, transactionId) {
    return `${VISA_FORM_ACTION}?redirect_to=${encodeURI(
      INTERSOCIO_CONFIRN_URL
    )}&session_token=${sessionToken}&transaction_id=${transactionId}`;
  }

  ngOnInit() {
    delete window.VisanetCheckout;
    this.systemSession = Session.getInstance();
    this.payment = this.systemSession.getPayment();
    this.getTransactionSessionToken();
  }

  private getTransactionSessionToken() {
    this.loadingVisa = true;
    this._visaService
      .getSessionToken({
        amount: this.payment.getTotal(),
        debt_ids: this.payment.getItems(),
      })
      .subscribe(
        (visaSession: any) => {
          if (visaSession.sessionKey) {
            this._createVisaForm(
              visaSession.sessionKey,
              visaSession.transaction_id,
              visaSession.visa_user_token_id
            );
          } else {
            this._roNotificationService.danger(
              'Hubo un error estableciendo la conexión con VISA.'
            );
          }
          this.loadingVisa = false;
        },
        error => {
          this._roNotificationService.danger(
            'No se pudo obtener una respuesta de parte de VISA'
          );
          this.loadingVisa = false;
        }
      );
  }

  private _createVisaForm(sessionToken, transactionId, userToken) {
    const script = document.createElement('script');
    script.setAttribute('src', VISA_FORM_SRC);
    script.setAttribute('data-sessiontoken', sessionToken);
    script.setAttribute('data-channel', 'web');
    script.setAttribute('data-merchantid', VISA_MERCHANT_ID);
    script.setAttribute('data-buttonsize', 'LARGE');
    script.setAttribute('data-buttoncolor', 'NAVY');
    script.setAttribute('data-merchantlogo', INTERSOCIO_LOGO_URL);
    script.setAttribute('data-merchantname', 'Club Internacional Arequipa');
    script.setAttribute('data-formbuttoncolor', '#0A0A2A');
    script.setAttribute('data-showamount', 'TRUE');
    script.setAttribute('data-purchasenumber', transactionId);
    script.setAttribute('data-amount', String(this.payment.getTotal()));
    script.setAttribute('dataexpirationminutes', '5');
    script.setAttribute('data-timeouturl', INTERSOCIO_TIMEOUT_URL);

    const dataUserToken = !isNil(userToken) ? userToken : '';
    script.setAttribute('data-usertoken', dataUserToken);
    script.setAttribute(
      'data-beneficiaryid',
      this.systemSession.getUser().username
    );

    const form = document.getElementById('visa-form');
    form.setAttribute(
      'action',
      PaymentVISAComponent._generateUrlActionVisa(sessionToken, transactionId)
    );
    form.appendChild(script);
  }
}
