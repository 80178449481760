import { Component, OnInit, ViewChild } from '@angular/core';
import { PostService } from '@app/services/social/post.service';
import { AuthService } from '@app/services/auth/auth.service';
import { PostListComponent } from '../post/post-list/post-list.component';

@Component({
  selector: 'app-feeds',
  providers: [PostService],
  templateUrl: './feeds.component.html',
})
export class FeedsComponent implements OnInit {
  constructor(
    private _postService: PostService,
    private _authService: AuthService
  ) {}

  @ViewChild(PostListComponent, {static: false}) postList: PostListComponent;

  loadingPosts = false;

  ngOnInit() {}

  getNewFeeds() {
    this.postList.getNewFeeds();
  }

  onLastPost = newPost => {
    this.postList.insertLastPost(newPost);
  }
}
