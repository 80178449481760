import { Component, OnInit } from '@angular/core';
import {AcademyService} from '@app/services/academy/academy.service';
import {Period} from '@app/classes/inscription/period';
import {environment} from '@env/environment';
import {RoNotificationService} from '@app/modules/shared/services/ro-notification.service';

@Component({
  selector: 'app-inscription-history-page',
  templateUrl: './inscription-history-page.component.html',
  styleUrls: ['./inscription-history-page.component.scss']
})
export class InscriptionHistoryPageComponent implements OnInit {
  currentPeriod: Period = new Period();
  constructor(
    private _academyService: AcademyService,
    private _roNotificationService: RoNotificationService
  ) { }

  ngOnInit() {
    this._getAcademyModuleState();
  }

  _getAcademyModuleState() {
    this._academyService.getState().subscribe(
      (infoModule: any) => {
        this.currentPeriod = new Period(infoModule.period);
      },
      error => {
        this._roNotificationService.danger('Hubo un error cargando la información');
      }
    );
  }
}

