import { Component, OnInit, Input } from '@angular/core';
import { Period } from '@app/classes/inscription/period';

@Component({
  selector: 'app-inscription-feeds',
  templateUrl: './inscription-feeds.component.html',
})
export class InscriptionFeedsComponent implements OnInit {
  constructor() {}

  @Input() period: Period;

  academyFeed = '';

  ngOnInit() {}
}
