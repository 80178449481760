import { Pipe, PipeTransform } from '@angular/core';

const data = [
  { code: 'C', description: 'CUOTA' },
  { code: 'O', description: 'OTRO' },
];

@Pipe({
  name: 'debtType',
})
export class DebtTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    for (let i = 0; i < data.length; i++)
      if (value == data[i].code) return data[i].description;
    return null;
  }
}
