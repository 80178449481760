import { Component, OnInit, Input } from '@angular/core';
import { Session } from '@app/classes/user/session';
import { Payment } from '@app/classes/payment/payment';
import { Debt } from '@app/classes/payment/debt';
import { AuthService } from '@app/services/auth/auth.service';
import { User } from '@app/classes/user/user';
import { filter, includes, forEach } from 'ramda';

declare var UIkit: any;

@Component({
    selector: 'app-payment-list',
    providers: [AuthService],
    templateUrl: './payment-list.component.html',
})
export class PaymentListComponent implements OnInit {
    systemSession: Session;
    sessionUser: User;

    constructor(private _authService: AuthService) {}

    @Input() payment: Payment;

    ngOnInit() {
        this.systemSession = Session.getInstance();
        this.sessionUser = Session.getInstance().getUser();
        this.payment = this.systemSession.getPayment();
    }

    removeItem(debt: Debt) {
        UIkit.modal.confirm(
            '<b>¿ Esta seguro que desea eliminar el elemento seleccionado ?</b>',
            () => {
                this.systemSession.getPayment().removeItem(debt);
                const siblings = filter(
                    (dbt: Debt) => includes(dbt.id, debt.siblingsIds),
                    this.payment.itemList
                );
                forEach(
                    (dbt: Debt) =>
                        this.systemSession.getPayment().removeItem(dbt),
                    siblings
                );
            },
            { center: true }
        );
    }

    validationRemove(index) {
        return this.payment.getItemsCount() - 1 === index;
    }
}
