import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Cookies from 'js-cookie';
import {PaymentConstants} from '@app/modules/payment/payment-constants';

@Component({
  selector: 'app-payment-visa-response-page',
  templateUrl: './payment-visa-response-page.component.html',
  styleUrls: ['./payment-visa-response-page.component.scss']
})
export class PaymentVisaResponsePageComponent implements OnInit {
  title = 'Pagos en línea';
  transactionId: string;
  transactionToken: string;
  constructor(
    private _routeActive: ActivatedRoute
  ) { }

  ngOnInit() {
    const { TRANSACTION_ID, TRANSACTION_TOKEN } = PaymentConstants.COOKIE_NAMES;
    this.transactionId = Cookies.get(TRANSACTION_ID);
    this.transactionToken = Cookies.get(TRANSACTION_TOKEN);
  }

}
