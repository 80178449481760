import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile-gallery-image',
  templateUrl: './profile-gallery-image.component.html',
})
export class ProfileGalleryImageComponent implements OnInit {
  constructor() {}

  @Input() image: any = {};
  @Input() post: any = {};
  @Input() onClickImage: Function = undefined;

  ngOnInit() {}

  clickImage() {
    if (this.onClickImage != undefined) {
      this.onClickImage(this.post);
    }
  }
}
