import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecurringPaymentsPageComponent } from './layout/recurring-payments-page/recurring-payments-page.component';
import { RecurringPaymentsRoutingModule } from '@app/modules/recurring-payments/recurring-payments-routing.module';
import {SharedModule} from '@app/modules/shared/shared.module';
import { RecurringPaymentMenuComponent } from './components/recurring-payment-menu/recurring-payment-menu.component';
import { RecurringPaymentInfoComponent } from './components/recurring-payment-info/recurring-payment-info.component';
import { RecurringPaymentNavComponent } from './components/recurring-payment-nav/recurring-payment-nav.component';
import { RecurringPaymentStatusCardComponent } from './components/recurring-payment-status-card/recurring-payment-status-card.component';
import { RecurringPaymentStatusIndicatorComponent } from './components/recurring-payment-status-indicator/recurring-payment-status-indicator.component';
import { RecurringPaymentAffiliationFormComponent } from './components/recurring-payment-affiliation-form/recurring-payment-affiliation-form.component';
import { RecurringPaymentDisaffiliationFormComponent } from './components/recurring-payment-disaffiliation-form/recurring-payment-disaffiliation-form.component';
import { RecurringPaymentStatusComponent } from './components/recurring-payment-status/recurring-payment-status.component';
import {RecurringPaymentResolverService} from '@app/modules/recurring-payments/resolvers/recurring-payment-resolver.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxInputTagModule } from '@ngx-lite/input-tag';
import {RecurringPaymentsStoreService} from '@app/modules/recurring-payments/data/recurring-payments-store.service';
import {DisaffiliationFormGuard} from '@app/modules/recurring-payments/guards/disaffiliation-form.guard';


@NgModule({
  declarations: [
    RecurringPaymentsPageComponent,
    RecurringPaymentMenuComponent,
    RecurringPaymentInfoComponent,
    RecurringPaymentNavComponent,
    RecurringPaymentStatusCardComponent,
    RecurringPaymentStatusIndicatorComponent,
    RecurringPaymentAffiliationFormComponent,
    RecurringPaymentDisaffiliationFormComponent,
    RecurringPaymentStatusComponent
  ],
  providers: [
    RecurringPaymentsStoreService,
    RecurringPaymentResolverService,
    DisaffiliationFormGuard,
  ],
    imports: [
        CommonModule,
        RecurringPaymentsRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        CreditCardDirectivesModule,
        NgxInputTagModule.forRoot(),
        FormsModule
    ]
})
export class RecurringPaymentsModule {}
