import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inscription-information',
  templateUrl: './inscription-information.component.html',
  styleUrls: ['./inscription-information.component.scss']
})
export class InscriptionInformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
