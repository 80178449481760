import {Component, Input, OnInit} from '@angular/core';
import { prop } from 'ramda';

const sizes = {
  sm: {
    left: 'md:tw-w-3/12',
    right: 'md:tw-w-9/12'
  },
  md: {
    left: 'md:tw-w-4/12',
    right: 'md:tw-w-8/12'
  },
  lg: {
    left: 'md:tw-w-5/12',
    right: 'md:tw-w-7/12'
  }
};

@Component({
  selector: 'app-module-layout-navigation',
  templateUrl: './module-layout-navigation.component.html',
  styleUrls: ['./module-layout-navigation.component.scss']
})
export class ModuleLayoutNavigationComponent implements OnInit {
  @Input() rowSize = 'sm';
  @Input() loading = false;

  constructor() { }

  get currentRowSize() {
    return prop(this.rowSize, sizes);
  }

  ngOnInit() {
  }

}
