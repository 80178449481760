import { format, formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';

export const formatRelativeDateTime = (date) => {
  return formatDistance(new Date(date), new Date(), { locale: es,  addSuffix: true });
};

export const formatFriendlyDateTime = (date, formatString = 'dd \'de\' MMMM yyyy') => {
  return  format(new Date(date), formatString, { locale: es });
};
