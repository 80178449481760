import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-empty-message',
  templateUrl: './payment-empty-message.component.html',
  styleUrls: ['./payment-empty-message.component.scss']
})
export class PaymentEmptyMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
