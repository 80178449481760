import { Pipe, PipeTransform } from '@angular/core';
import { PEN, USD } from '@app/helpers/currency-functions';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  transform(value: any, currency: string = 'PEN'): any {
    switch (currency) {
      case 'PEN':
        return PEN(value).format();
      case 'USD':
        return USD(value).format();
      default:
        return value;
    }
  }
}
